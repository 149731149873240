import React, {useState, useEffect, useMemo} from 'react'
import {Button, Card, Col, Container, Row} from 'react-bootstrap'
import {getRemainingOrders, postRemainingOrders} from '../../services/api'
import {useTranslation} from 'react-i18next'
import ReactTable from '../../components/ReactTable/ReactTable'
import DateUtil from '../../utils/DateUtil'
import Affected from '../../enums/Affected'
import Corrective from '../../enums/Corrective'

export default function RemainingOrders(props) {
  const {t} = useTranslation()
  const [orders, setOrders] = useState([])

  const basketColumns = [
    {
      Header: t('common.id'),
      accessor: 'id',
      Footer: info => {
        const total = useMemo(() => info.rows.length)
        return (
          <>
            {t('table.countRows')} {total}
          </>
        )
      },
    },
    {
      id: 'datetime',
      Header: t('remaining.datetime'),
      accessor: d => DateUtil.convertToPersian(d.datetime),
    },
    {
      Header: t('remaining.currency'),
      accessor: d => d.currency?.symbol,
    },
    {
      Header: t('remaining.amount'),
      accessor: 'amount',
    },
    {
      id: 'affected',
      Header: t('remaining.affected'),
      accessor: d => Affected.getById(d.affected).title,
    },
    {
      Header: t('remaining.action'),
      accessor: 'action',
    },
  ]

  useEffect(() => {
    getRemainingOrders()
      .then(response => setOrders(response.data))
      .catch(err => alert(JSON.stringify(err)))
  }, [])

  const handlerRemaining = () => {
    postRemainingOrders()
      .then(response => {
        alert('عملیات با موفقیت انجام شد')
      })
      .catch(err => alert(JSON.stringify(err)))
  }

  return (
    <>
      <Container fluid>
        <Button className="btn-fill" variant="info" onClick={handlerRemaining}>
          {t('remaining.remainingActions')}
        </Button>
        {orders.length > 0 && (
          <Row>
            <Col md="12">
              <Card>
                <Card.Body>
                  <ReactTable
                    showPagination={false}
                    data={orders}
                    columns={basketColumns}
                    className="-striped -highlight primary-pagination"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </>
  )
}
