const corrective = Object.freeze({
    ACTIVE: {id: true, title: "اصلاحی دارد"},
    NOT_ACTIVE: {id: false, title: "اصلاحی ندارد"}
});

class Corrective
{
    getById(id){
        let selectedStatus =Object.entries(corrective).find(value => value[1].id === id);
        return corrective[selectedStatus[0]];
    }

    getValues(){
        return Object.keys(corrective);
    }

}

export default new Corrective();
