import React from 'react'
import {Button, Card, Col, Container, Form, Row} from 'react-bootstrap'
import {Formik} from 'formik'
import {editNetwork} from '../../services/api'
import {useTranslation} from 'react-i18next'

export default function EditNetwork(props) {
  const {t} = useTranslation()
  const {onClose, selectedNetwork, editStateNetwork} = props
  let basket = {
    name: {fa: selectedNetwork.name.fa},
    symbol: selectedNetwork.symbol,
    id: selectedNetwork?.id,
  }

  console.log(selectedNetwork, 'selectedNetwork')
  return (
    <>
      <Container fluid>
        <Row sm={1} md={2}>
          <Formik
            initialValues={basket}
            onSubmit={values => {
              editNetwork(values)
                .then(response => {
                  alert('عملیات با موفقیت انجام شد')
                  // window.location.reload();
                  editStateNetwork(prev => {
                    let array = prev
                    const editedData = array.find(
                      item => item.id === response.data.id,
                    )
                    array = array.filter(item => item !== editedData)

                    const newArray = [
                      ...array,
                      {
                        ...response.data,
                        name: response.data.name.fa,
                        actions: editedData.actions,
                      },
                    ]

                    return newArray
                  })
                })
                .catch(err => {
                  alert(JSON.stringify(err))
                })
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Card className="stacked-form">
                  <Card.Header>
                    <Card.Title as="h4">{t('network.edit')}</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Form.Group>
                      <label>{t('network.name')}</label>
                      <Form.Control
                        placeholder="name"
                        type="text"
                        name="name"
                        onChange={event => {
                          setFieldValue('name', {fa: event.target.value})
                        }}
                        onBlur={handleBlur}
                        value={values.name.fa}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group>
                      <label>{t('network.symbol')}</label>
                      <Form.Control
                        placeholder="symbol"
                        type="text"
                        name="symbol"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.symbol}
                      ></Form.Control>
                    </Form.Group>
                  </Card.Body>
                  <Card.Footer>
                    <Row>
                      <Col md={2} className="ml-3">
                        <Button
                          className="btn-fill"
                          type="submit"
                          variant="info"
                        >
                          {t('network.edit')}
                        </Button>
                      </Col>
                      <Col md={2}>
                        <Button
                          className="btn-fill"
                          onClick={onClose}
                          variant="info"
                        >
                          {t('common.close')}
                        </Button>
                      </Col>
                    </Row>
                  </Card.Footer>
                </Card>
              </Form>
            )}
          </Formik>
        </Row>
      </Container>
    </>
  )
}
