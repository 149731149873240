import React, {useEffect, useMemo, useState} from 'react'
import {Container, Button, Row, Col, Card} from 'react-bootstrap'
import {FormGroup, Input} from 'reactstrap'
import {loadChargeRequestList} from '../../services/api'
import ReactTable from '../../components/ReactTable/ReactTable'
import Select from 'react-select'
import ChargeResult from './ChargeResult'
import {useTranslation} from 'react-i18next'
import SaleStatus from '../../enums/SaleStatus'
import ChargeDetail from './ChargeDetail'
import Network from '../../enums/Network'

export default function ChargeRequestList(props) {
  const {t} = useTranslation()
  const statusOption = [
    {label: 'در صف', value: 'A'},
    {label: 'نیاز به TXID', value: 'C'},
    {label: 'TXID نامعتبر', value: 'I'},
    {label: 'انجام شده', value: 'D'},
  ]

  const initialStates = useMemo(
    () => ({
      ChargeRequestList: [],
      selectedOrder: false,
      showTransactionDialog: false,
      assignedWallet: {},
      showModalWallet: false,
      showWalletDialog: false,
      showDetailDialog: false,
      loading: false,
      page: 0,
      size: 0,
      count: 0,
      next: '',
      id: '',
      user: '',
      status: '',
      currency: '',
      sort_field: '-create_datetime',
      curr_amount: '',
      txid: '',
    }),
    [],
  )

  const [state, setState] = useState(initialStates)

  const {
    ChargeRequestList,
    selectedOrder,
    showTransactionDialog,
    assignedWallet,
    showModalWallet,
    showWalletDialog,
    showDetailDialog,
    loading,
    page,
    size,
    count,
    next,
    user,
    id,
    status,
    currency,
    sort_field,
    curr_amount,
    txid,
  } = state

  const setShowDetailDialog = value => {
    setState(prev => ({
      ...prev,
      showDetailDialog: value,
    }))
  }
  const setSelectedOrder = value => {
    setState(prev => ({
      ...prev,
      selectedOrder: value,
    }))
  }
  const setShowTransactionDialog = value => {
    setState(prev => ({
      ...prev,
      showTransactionDialog: value,
    }))
  }

  const setAssignedWallet = value => {
    setState(prev => ({
      ...prev,
      assignedWallet: value,
      showModalWallet: true,
    }))
  }
  const setShowWalletDialog = value => {
    setState(prev => ({
      ...prev,
      showWalletDialog: value,
    }))
  }

  const chargeColumns = [
    {
      Header: t('common.customer_name'),
      size: 4,
      accessor: d => `${d.customer.name}`,
      Footer: () => {
        return (
          <>
            {t('table.countRows')} {count}
          </>
        )
      },
    },
    {
      Header: t('charge.table.curr_amount'),
      accessor: d => d.charge.curr_amount,
    },
    // {
    //   Header: t('charge.table.currency'),
    //   accessor: d => d.charge.currency.symbol,
    // },
    // {
    //   Header: t('charge.table.network'),
    //   accessor: d => d.charge.currency.name.fa,
    // },
    {
      Header: 'txid',
      accessor: d => d.charge.txid,
    },
    {
      Header: t('common.status'),
      accessor: d => SaleStatus.getById(d.charge.status).title,
    },

    {
      Header: t('charge.table.done_total'),
      accessor: d =>
        d.charge.done_total
          ?.toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
    },
    {
      Header: t('common.actions'),
      accessor: 'actions',
      sortable: false,
      filterable: false,
    },
  ]

  const dataChargeHandler = data => {
    const chargeData = data.map((prop, key) => {
      return {
        ...prop,
        actions: (
          <div className="actions-right">
            <Button
              onClick={() => {
                setSelectedOrder(prop)
                setShowDetailDialog(true)
              }}
              variant="info"
              size="sm"
              className="text-info btn-link like"
            >
              <i className="fa fa-eye" />
            </Button>
            <Button
              onClick={() => {
                setSelectedOrder(prop)
                setShowTransactionDialog(true)
              }}
              variant="warning"
              size="sm"
              className="text-warning btn-link edit"
            >
              <i className="fa fa-edit" />
            </Button>
          </div>
        ),
      }
    })
    setState(prev => ({
      ...prev,
      ChargeRequestList: chargeData,
    }))
  }

  const fetchData = (page, size) => {
    if (page > 0 && size > 0) {
      const data = {
        page: page,
        size: size,
        id: id,
        user: user,
        status: status,
        currency: currency,
        sort_field: sort_field,
        curr_amount: curr_amount,
        txid: txid,
      }
      setState(prev => ({
        ...prev,
        loading: true,
      }))
      loadChargeRequestList(data)
        .then(response => {
          dataChargeHandler(response.data.results)
          setState(prev => ({
            ...prev,
            count: response.data.count,
            next: response.data.next,
            loading: false,
          }))
        })
        .catch(err => {
          alert('عملیات با خطا مواجه شد')
          alert(JSON.stringify(err))
          setState(prev => ({
            ...prev,
            loading: false,
          }))
        })
    }
  }

  useEffect(() => {
    let timeout = null
    if (!timeout) {
      timeout = setTimeout(() => {
        fetchData(1, 10)
      }, 800)
    }

    return () => clearTimeout(timeout)
  }, [user, status, currency, sort_field, id, curr_amount, txid])

  return (
    <>
      <Container fluid>
        {selectedOrder && (
          <ChargeResult
            charge={selectedOrder.charge}
            show={showTransactionDialog}
            onHide={() => setShowTransactionDialog(false)}
          />
        )}
        {selectedOrder && (
          <ChargeDetail
            charge={selectedOrder.charge}
            show={showDetailDialog}
            onHide={() => setShowDetailDialog(false)}
          />
        )}{' '}
        <Row>
          <Col md="12">
            <h5>گزارشات واریز</h5>
            <Card>
              <div className="filter-area">
                <Col md="4">
                  <FormGroup>
                    <label>نام مشتری</label>
                    <Input
                      name="user"
                      placeholder="user"
                      type="text"
                      onChange={e => {
                        setState(prev => ({
                          ...prev,
                          user: e.target.value || '',
                        }))
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label>وضعیت</label>
                    <Select
                      name="status"
                      options={statusOption}
                      palceholder="status"
                      onChange={event => {
                        setState(prev => ({
                          ...prev,
                          status: event.value,
                        }))
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col md="4">
                  <FormGroup>
                    <label>شناسه</label>
                    <Input
                      name="id"
                      placeholder="id"
                      type="text"
                      onChange={e => {
                        setState(prev => ({
                          ...prev,
                          id: e.target.value || '',
                        }))
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label>مقدار ارز </label>
                    <Input
                      name="curr_amount"
                      placeholder="curr_amount"
                      type="text"
                      onChange={e => {
                        setState(prev => ({
                          ...prev,
                          curr_amount: e.target.value || '',
                        }))
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label>txid</label>
                    <Input
                      name="txid"
                      placeholder="txid"
                      type="text"
                      onChange={e => {
                        setState(prev => ({
                          ...prev,
                          txid: e.target.value || '',
                        }))
                      }}
                    />
                  </FormGroup>
                </Col>
              </div>
              <Card.Body>
                <ReactTable
                  data={ChargeRequestList}
                  count={count}
                  columns={chargeColumns}
                  next={next}
                  className="-striped -highlight primary-pagination"
                  fetchingData={(pageIndex, pageSize) =>
                    fetchData(pageIndex, pageSize)
                  }
                  loading={loading}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}
