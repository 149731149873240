import React, { useEffect, useState } from "react";
import VerifyDebits from "./VerifyDebits";
import VerifyPersonalPicture from "./VerifyPersonalPicture";
import VerifyNationalCard from "./VerifyNationalCard";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {
  verifyCustomerProfile,
  verifyCustomerProfileDebit,
} from "../../services/api";
import { useTranslation } from "react-i18next";
import update from "immutability-helper";
import { Form } from "react-bootstrap";

export default function VerifyCustomerProfile(props) {
  const { selectedCustomer, onHide, profiles, setprofiles } = props;
  const [verification, setVerification] = useState({
    isPersonalPhotoVerified:
      selectedCustomer.personal_photo_cart_melli_verification,
    isNationalCardPhotoVerified: selectedCustomer.melli_cart_verification,
    isNationalCodeVerified: selectedCustomer.melli_code_verification,
  });

  const [debitVerifications, setDebitVerifications] = useState([]);
  const [rejectComment, setRejectComment] = useState({ data: {} });

  const [showRejectBtn, setshowRejectBtn] = useState(true);
  const [showConfirmBtn, setshowConfirmBtn] = useState(true);

  useEffect(() => {
    setRejectComment(
      { ...rejectComment },
      (rejectComment.data[selectedCustomer.melli_code] =
        selectedCustomer.rejection_comments)
    );
  }, [selectedCustomer]);
  // const [showRejectComment,setShowRejectComment] = useState(false);

  const { t } = useTranslation();

  const updateProfile = (requestDto) => {
    verifyCustomerProfile(selectedCustomer.id, requestDto)
      .then((response) => {
        alert("ثبت اطلاعات با موفقیت انجام شد");
        let index = profiles.findIndex(
          (value) => value.id === selectedCustomer.id
        );
        let profile = profiles[index];
        profile.to_admin = response.data.to_admin;
        profile.is_active = response.data.is_active;
        profile.personal_photo_cart_melli_verification =
          response.data.personal_photo_cart_melli_verification;
        profile.melli_code_verification = response.data.melli_code_verification;
        profile.melli_cart_verification = response.data.melli_cart_verification;
        profile.rejection_comments = response.data.rejection_comments;
        setRejectComment(
          { ...rejectComment },
          (rejectComment.data[selectedCustomer.melli_code] =
            response.data.rejection_comments)
        );
        let dataRows = update(profiles, {
          $splice: [[index, 1, profile]],
        });
        setprofiles(dataRows);
      })
      .catch((err) => alert(JSON.stringify(err)));
  };

  const verifyProfile = () => {
    let verificationDto = {
      personal_photo_cart_melli_verification:
        verification.isPersonalPhotoVerified,
      melli_code_verification: verification.isNationalCodeVerified,
      melli_cart_verification: verification.isNationalCardPhotoVerified,
      is_active: true,
      to_admin: false,
      fully_rejected: false,
      rejection_comments: "",
    };
    Promise.all(
      debitVerifications.map((item) => {
        return verifyCustomerProfileDebit(item.id, {
          verified: item.verified,
        })
          .then((response) => {
            return response;
          })
          .catch((err) => alert(JSON.stringify(err)));
      })
    )
      .then((res) => {
        let existOneActiveDebit = res.some(
          (value) => value.status === 200 && value.data.verified === "V"
        );
        if (!existOneActiveDebit) return;
        updateProfile(verificationDto);
      })
      .catch((err) => alert(JSON.stringify(err)));
    onHide();
  };

  const rejectProfile = () => {
    if (!rejectComment.data[selectedCustomer.melli_code]) {
      alert("برای رد پروفایل مشتری ثبت دلایل الزامیست");
      return;
    }
    let rejectionDto = {
      personal_photo_cart_melli_verification:
        verification.isPersonalPhotoVerified,
      melli_code_verification: verification.isNationalCodeVerified,
      melli_cart_verification: verification.isNationalCardPhotoVerified,
      is_active: false,
      to_admin: false,
      fully_rejected: true,
      rejection_comments: rejectComment.data[selectedCustomer.melli_code],
    };

    updateProfile(rejectionDto);

    onHide();
    setRejectComment({ data: {} });
  };

  const rejectProfilePartially = () => {
    if (!rejectComment.data[selectedCustomer.melli_code]) {
      alert("برای رد پروفایل مشتری ثبت دلایل الزامیست");
      return;
    }

    let rejectDto = {
      personal_photo_cart_melli_verification:
        verification.isPersonalPhotoVerified,
      melli_code_verification: verification.isNationalCodeVerified,
      melli_cart_verification: verification.isNationalCardPhotoVerified,
      is_active: true,
      to_admin: false,
      fully_rejected: false,
      rejection_comments: rejectComment.data[selectedCustomer.melli_code],
    };
    Promise.all(
      debitVerifications.map((item) => {
        return verifyCustomerProfileDebit(item.id, {
          verified: item.verified,
        })
          .then((response) => {
            return response;
          })
          .catch((err) => alert(JSON.stringify(err)));
      })
    )
      .then(() => {
        updateProfile(rejectDto);
      })
      .catch((err) => alert(JSON.stringify(err)));

    onHide();
    setRejectComment({ data: {} });
  };

  useEffect(() => {
    if (
      verification.isNationalCardPhotoVerified === "V" &&
      verification.isNationalCodeVerified === "V" &&
      verification.isPersonalPhotoVerified === "V" &&
      debitVerifications[0]?.verified === "V"
    ) {
      setshowRejectBtn(false);
    } else {
      setshowRejectBtn(true);
    }

    if (
      verification.isNationalCardPhotoVerified === "R" ||
      verification.isNationalCodeVerified === "R" ||
      verification.isPersonalPhotoVerified === "R" ||
      debitVerifications[0]?.verified === "R"
    ) {
      setshowConfirmBtn(false);
    } else {
      setshowConfirmBtn(true);
    }
  }, [verification, debitVerifications]);

  return (
    <>
      {selectedCustomer && (
        <Modal
          size="lg"
          centered
          {...props}
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {t("customerProfiles.info")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
            <Container>
              <Row>
                <Col xs={6} md={16}>
                  <div>
                    <h6>{t("common.customer_name")}</h6>
                    <p className="category">
                      {selectedCustomer.first_name} {selectedCustomer.last_name}
                    </p>
                  </div>
                </Col>
                <Col xs={6} md={16}>
                  <div>
                    <h6>{t("customerProfiles.phone_num")}</h6>
                    <p className="category">{selectedCustomer.phone_num}</p>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={12}>
                  <VerifyDebits
                    userId={selectedCustomer.user}
                    setDebitVerifications={setDebitVerifications}
                    debitVerifications={debitVerifications}
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={12}>
                  <VerifyNationalCard
                    nationalCard={selectedCustomer}
                    setVerification={setVerification}
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={12}>
                  <VerifyPersonalPicture
                    personalPhoto={selectedCustomer}
                    setVerification={setVerification}
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={12}>
                  <Form.Group>
                    <label>دلایل رد پروفایل</label>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      name="rejectComments"
                      type="text"
                      value={rejectComment.data[selectedCustomer.melli_code]}
                      onChange={(event) =>
                        setRejectComment(
                          { ...rejectComment },
                          (rejectComment.data[selectedCustomer.melli_code] =
                            event.target.value)
                        )
                      }
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onHide}> {t("common.close")}</Button>
            {showConfirmBtn && (
              <Button onClick={verifyProfile}>
                {" "}
                {t("customerProfiles.verifyTotally")}
              </Button>
            )}

            {showRejectBtn && (
              <Button onClick={rejectProfilePartially}>
                {" "}
                {t("customerProfiles.rejectPartially")}
              </Button>
            )}

            {showRejectBtn && (
              <Button onClick={rejectProfile}>
                {" "}
                {t("customerProfiles.rejectTotally")}
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
