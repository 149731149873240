import React, {useEffect, useMemo, useState, useCallback} from 'react'
import {loadSaleWallets, getCurrencies, currencies} from '../../services/api'
import {Container} from 'react-bootstrap'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import ReactTable from '../../components/ReactTable/ReactTable'
import {useTranslation} from 'react-i18next'
import Button from 'react-bootstrap/Button'
import CreateSaleWallet from './CreateSaleWallet'
import EditSaleWallet from './EditSaleWallet'
import Active from '../../enums/Active'

export default function SaleWallets() {
  const {t} = useTranslation()
  const walletColumns = [
    {
      Header: t('common.id'),
      accessor: 'id',
      Footer: info => {
        const total = useMemo(() => info.rows.length)
        return (
          <>
            {t('table.countRows')} {total}
          </>
        )
      },
    },
    {
      Header: t('common.name'),
      accessor: 'name',
    },
    {
      Header: t('common.network'),
      accessor: 'network',
    },
    {
      Header: t('common.currency'),
      accessor: d => d.currency.symbol,
    },
    {
      Header: t('common.address'),
      accessor: 'address',
    },
    {
      id: 'active',
      Header: t('common.active'),
      accessor: d => Active.getById(d.active).title,
    },
    {
      Header: t('common.dist_tag'),
      accessor: 'dist_tag',
    },
    {
      Header: t('common.actions'),
      accessor: 'actions',
      sortable: false,
      filterable: false,
    },
  ]

  const [showCreateWalletForm, setShowCreateWalletForm] = useState(false)
  const [wallets, setWallets] = useState([])
  const [selectedSaleWallet, setSelectedSaleWallet] = useState(null)
  const [showEditSaleWallet, setShowEditSaleWallet] = useState(false)
  const [listCurrencies, setListCurrencies] = useState({data: []})

  const setSelectedvalue = useCallback(value => {
    setSelectedSaleWallet({
      id: value.id,
      name: value.name,
      address: value.address,
      dist_tag: value.dist_tag,
      active: value.active,
      qr_code: value.qr_code,
      currency: value.currency.id,
    })
    setShowEditSaleWallet(true)
  })

  const loadListOfWallet = useCallback(() => {
    getCurrencies()
      .then(response => {
        response.data.results.forEach(item => {
          let flagDta = {}
          flagDta.label = `${item.symbol} - ${item.network.symbol}`
          flagDta.value = item.id

          listCurrencies.data.push(flagDta)
          setListCurrencies({...listCurrencies})
        })

        return response.data
      })
      .then(currencies =>
        loadSaleWallets()
          .then(response =>
            setWallets(
              response.data
                .map(prop => {
                  return {
                    ...prop,
                    actions: (
                      <div className="actions-right">
                        <Button
                          onClick={() => setSelectedvalue(prop)}
                          variant="warning"
                          size="sm"
                          className="text-warning btn-link edit"
                        >
                          <i className="fa fa-edit" />
                        </Button>
                      </div>
                    ),
                  }
                })
                .sort((a, b) => (a.id > b.id ? -1 : 1)),
            ),
          )
          .catch(err => console.log(err)),
      )
      .catch(err => alert(JSON.stringify(err)))
  }, [])

  useEffect(() => {
    loadListOfWallet()
  }, [])

  return (
    <>
      <Container fluid>
        {!showCreateWalletForm && (
          <Button
            color="primary"
            onClick={() => setShowCreateWalletForm(true)}
            variant="info"
          >
            + {t('saleWallet.create')}
          </Button>
        )}
        {showCreateWalletForm && (
          <CreateSaleWallet
            setWallets={setWallets}
            onClose={() => {
              setShowCreateWalletForm(false)
              loadListOfWallet()
            }}
            listCurrencies={listCurrencies}
          />
        )}
        {showEditSaleWallet && selectedSaleWallet ? (
          <EditSaleWallet
            setWallets={setWallets}
            onClose={() => {
              setShowEditSaleWallet(false)
              loadListOfWallet()
            }}
            selectedWallet={selectedSaleWallet}
            listCurrencies={listCurrencies}
          />
        ) : (
          <></>
        )}

        {wallets.length > 0 && (
          <Row>
            <Col md="12">
              <Card>
                <Card.Body>
                  <ReactTable
                    data={wallets}
                    columns={walletColumns}
                    showPagination={false}
                    className="-striped -highlight primary-pagination"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </>
  )
}
