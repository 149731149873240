/*eslint-disable*/
import React, {useState, useMemo, useEffect, Fragment} from 'react'
import {
  useTable,
  useFilters,
  useAsyncDebounce,
  useSortBy,
  usePagination,
} from 'react-table'
import classnames from 'classnames'
import Select from 'react-select'
import loadingGif from 'assets/img/loading.gif'

import {Container, Row, Col, FormGroup, Input} from 'reactstrap'
import {useTranslation} from 'react-i18next'

function ReactTable(props) {
  const {columns, data, count, fetchingData, next, loading, showPagination} =
    props
  const [t] = useTranslation()
  const [numberOfRows, setNumberOfRows] = useState({
    value: 10,
    label: `10 رکورد`,
  })
  const [pageSelect, handlePageSelect] = useState({
    value: 0,
    label: `صفحه 1`,
  })
  const [pageIndexState, setPageIndexState] = useState(1)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    page,
    prepareRow,
    state,
    visibleColumns,
    nextPage,
    pageOptions,
    pageCount,
    previousPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
    state: {pageIndex, pageSize},
    setHiddenColumns,
  } = useTable(
    {
      manualPagination: true,
      pageCount: Math.ceil(count / numberOfRows.value),
      columns,
      data,
      // defaultColumn, // Be sure to pass the defaultColumn option
      // filterTypes,
      initialState: {pageIndex: 1, pageSize: 10},
    },
    useFilters, // useFilters!
    useSortBy,
    usePagination,
  )
  let pageSelectData = Array.apply(null, Array(pageOptions.length)).map(
    function () {},
  )
  let numberOfRowsData = [5, 10, 20, 25, 50, 100, 200]

  return (
    <>
      {loading ? (
        <div className="loading-area">
          <img alt="loading" src={loadingGif}></img>
        </div>
      ) : (
        <div className="ReactTable -striped -highlight primary-pagination">
          {showPagination !== false && (
            <div className="pagination-top">
              <div className="-pagination">
                <div className="-previous">
                  <button
                    type="button"
                    onClick={() => {
                      fetchingData(pageIndexState - 1, pageSize)
                      handlePageSelect({
                        value: pageIndexState,
                        label: `صفحه ${pageIndexState - 1}`,
                      })
                      setPageIndexState(pageIndexState - 1)
                    }}
                    disabled={pageIndexState === 1 ? true : false}
                    className="-btn"
                  >
                    {t('table.previous')}
                  </button>
                </div>
                <div className="-center">
                  <Container>
                    <Row className="justify-content-center">
                      <Col md="6" sm="6" xs="12">
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="pageSelect"
                          value={pageSelect}
                          onChange={value => {
                            setPageIndexState(value.value + 1)
                            handlePageSelect(value)

                            fetchingData(value.value + 1, pageSize)
                          }}
                          options={pageSelectData.map((prop, key) => {
                            return {
                              value: key,
                              label: `${t('table.page')} ` + (key + 1),
                            }
                          })}
                          placeholder={t('table.choosePage')}
                        />
                      </Col>
                      <Col md="6" sm="6" xs="12">
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="numberOfRows"
                          value={numberOfRows}
                          onChange={value => {
                            if (pageIndexState > 1) {
                              setPageIndexState(1)
                              handlePageSelect({
                                value: 0,
                                label: `صفحه ${1}`,
                              })
                              setNumberOfRows(value)
                              fetchingData(1, value.value)
                            } else {
                              setPageSize(value.value)
                              setNumberOfRows(value)

                              fetchingData(pageIndexState, value.value)
                            }
                          }}
                          options={numberOfRowsData.map(prop => {
                            return {
                              value: prop,
                              label: prop + ` ${t('table.rows')}`,
                            }
                          })}
                          placeholder={t('table.chooseRows')}
                        />
                      </Col>
                    </Row>
                  </Container>
                </div>
                <div className="-next">
                  <button
                    type="button"
                    onClick={() => {
                      fetchingData(pageIndexState + 1, pageSize)
                      handlePageSelect({
                        value: pageIndexState,
                        label: `صفحه ${pageIndexState + 1}`,
                      })
                      setPageIndexState(pageIndexState + 1)
                    }}
                    // disabled={pageIndexState === 1 ? true : false}
                    // onClick={() => nextPage()}
                    disabled={next === null ? true : false}
                    className="-btn"
                  >
                    {t('table.next')}
                  </button>
                </div>
              </div>
            </div>
          )}

          <table {...getTableProps()} className="rt-table">
            <thead className="rt-thead -header">
              {headerGroups.map((headerGroup, index) => (
                <Fragment key={`table-th-${index}-header`}>
                  <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                    {headerGroup.headers.map((column, key) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps(),
                        )}
                        className={classnames('rt-th rt-resizable-header', {
                          '-cursor-pointer':
                            headerGroup.headers.length - 1 !== key,
                          '-sort-asc': column.isSorted && !column.isSortedDesc,
                          '-sort-desc': column.isSorted && column.isSortedDesc,
                        })}
                      >
                        <div className="rt-resizable-header-content">
                          {column.render('Header')}
                        </div>
                      </th>
                    ))}
                  </tr>
                </Fragment>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="rt-tbody">
              {page.map((row, i) => {
                prepareRow(row)
                return (
                  <tr
                    {...row.getRowProps()}
                    className={classnames(
                      'rt-tr',
                      {' -odd': i % 2 === 0},
                      {' -even': i % 2 === 1},
                    )}
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <td {...cell.getCellProps()} className="rt-td">
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
            <tfoot>
              {footerGroups.map(group => (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map(column => (
                    <td {...column.getFooterProps()}>
                      {column.render('Footer')}
                    </td>
                  ))}
                </tr>
              ))}
            </tfoot>
          </table>

          <div className="pagination-bottom"></div>
        </div>
      )}
    </>
  )
}

export default ReactTable
