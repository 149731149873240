import React, {useEffect, useState} from 'react'
import {Button, Card, Col, Container, Form, Row} from 'react-bootstrap'
import {Formik} from 'formik'
import {loadDefaultSystemParams, updateSystemParams} from '../../services/api'
import {useTranslation} from 'react-i18next'
import VandarToken from './VandarToken'

export default function SystemParameters(props) {
  const {t} = useTranslation()

  let params = {
    new_user: '',
    trade: '',
    bestbuy_min_tether: '',
    bestbuy_max_tether: '',
    min_sell_tether: '',
    min_sell_tether: '',
    min_sell_tether_threshold: '',
    max_sell_tether_threshold: '',
    min_buy: '',
    max_buy: '',
    alpha1_usdt: '',
    alpha2_usdt: '',
    beta1_usdt: '',
    beta2_usdt: '',
    referral_bonous_tether: '',
    admin_num1: '',
    admin_num2: '',
    admin_num3: '',
    first_bestbuy_parameter: '',
    second_bestbuy_parameter: '',
    bestbuy_first_fee: '',
    bestbuy_last_fee: '',
    rebalance_fee: '',
  }

  const [systemParams, setSystemParams] = useState(params)
  const [showVandarToken, setShowVandarToken] = useState(false)

  const createVandarToken = () => {
    setShowVandarToken(true)
  }

  useEffect(() => {
    loadDefaultSystemParams(1)
      .then(response => setSystemParams(response.data))
      .catch(err => console.log(err))
  }, [])

  return (
    <>
      <Container fluid>
        {systemParams && (
          <VandarToken
            show={showVandarToken}
            onHide={e => setShowVandarToken(false)}
          />
        )}
        {systemParams && (
          <Row>
            <Formik
              enableReinitialize
              initialValues={systemParams}
              onSubmit={values => {
                updateSystemParams(1, values)
                  .then(value => alert(JSON.stringify(value)))
                  .catch(err => console.log(err))
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Card className="stacked-form">
                    <Card.Header>
                      <Card.Title as="h4">{t('configParam.title')}</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <br />
                      <Row>
                        <Col md={6}>
                          <Form.Check>
                            <Form.Check.Label>
                              <Form.Check.Input
                                type="checkbox"
                                name="new_user"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={values.new_user}
                              ></Form.Check.Input>
                              <span className="form-check-sign"></span>
                              {t('configParam.newUser')}
                            </Form.Check.Label>
                          </Form.Check>
                        </Col>
                        <Col md={6}>
                          <Form.Check>
                            <Form.Check.Label>
                              <Form.Check.Input
                                type="checkbox"
                                name="trade"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={values.trade}
                              ></Form.Check.Input>
                              <span className="form-check-sign"></span>
                              {t('configParam.trade')}
                            </Form.Check.Label>
                          </Form.Check>
                        </Col>
                      </Row>

                      <br />
                      <Row>
                        <Col md={3}>
                          <Form.Group>
                            <label>{t('configParam.min_sell_rial')}</label>
                            <Form.Control
                              placeholder="min_sell_tether"
                              type="text"
                              name="min_sell_tether"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.min_sell_tether}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group>
                            <label>{t('configParam.max_sell_rial')}</label>
                            <Form.Control
                              placeholder="min_sell_tether"
                              type="text"
                              name="min_sell_tether"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.min_sell_tether}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group>
                            <label>{t('configParam.min_buy')}</label>
                            <Form.Control
                              placeholder="min_buy"
                              type="text"
                              name="min_buy"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.min_buy}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group>
                            <label>{t('configParam.max_buy')}</label>
                            <Form.Control
                              placeholder="max_buy"
                              type="text"
                              name="max_buy"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.max_buy}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={3}>
                          <Form.Group>
                            <label>
                              {t('configParam.first_balance_parameter')}
                            </label>
                            <Form.Control
                              placeholder="first_bestbuy_parameter"
                              type="text"
                              name="first_bestbuy_parameter"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.first_bestbuy_parameter}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group>
                            <label>
                              {t('configParam.second_balance_parameter')}
                            </label>
                            <Form.Control
                              placeholder="second_bestbuy_parameter"
                              type="text"
                              name="second_bestbuy_parameter"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.second_bestbuy_parameter}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group>
                            <label>{t('configParam.bestbuy_min_tether')}</label>
                            <Form.Control
                              placeholder="bestbuy_min_tether"
                              type="text"
                              name="bestbuy_min_tether"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.bestbuy_min_tether}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group>
                            <label>{t('configParam.bestbuy_max_tether')}</label>
                            <Form.Control
                              placeholder="bestbuy_max_tether"
                              type="text"
                              name="bestbuy_max_tether"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.bestbuy_max_tether}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={3}>
                          <Form.Group>
                            <label>
                              {t('configParam.min_sell_rial_threshold')}
                            </label>
                            <Form.Control
                              placeholder="min_sell_tether_threshold"
                              type="text"
                              name="min_sell_tether_threshold"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.min_sell_tether_threshold}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group>
                            <label>
                              {t('configParam.max_sell_rial_threshold')}
                            </label>
                            <Form.Control
                              placeholder="max_sell_tether_threshold"
                              type="text"
                              name="max_sell_tether_threshold"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.max_sell_tether_threshold}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group>
                            <label>{t('configParam.balance_first_fee')}</label>
                            <Form.Control
                              placeholder=" bestbuy_first_fee"
                              type="text"
                              name=" bestbuy_first_fee"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.bestbuy_first_fee}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group>
                            <label>{t('configParam.balance_last_fee')}</label>
                            <Form.Control
                              placeholder="bestbuy_last_fee"
                              type="text"
                              name="bestbuy_last_fee"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.bestbuy_last_fee}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={3}>
                          <Form.Group>
                            <label>{t('configParam.rebalance_fee')}</label>
                            <Form.Control
                              placeholder="rebalance_fee"
                              type="text"
                              name="rebalance_fee"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.rebalance_fee}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group>
                            <label>
                              {t('configParam.referral_bonous_rial')}
                            </label>
                            <Form.Control
                              placeholder="referral_bonous_tether"
                              type="text"
                              name="referral_bonous_tether"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.referral_bonous_tether}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group>
                            <label>alpha1_usdt</label>
                            <Form.Control
                              placeholder="alpha1_usdt"
                              type="text"
                              name="alpha1_usdt"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.alpha1_usdt}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group>
                            <label>alpha2_usdt</label>
                            <Form.Control
                              placeholder="alpha2_usdt"
                              type="text"
                              name="alpha2_usdt"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.alpha2_usdt}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={3}>
                          <Form.Group>
                            <label>beta1_usdt</label>
                            <Form.Control
                              placeholder="beta1_usdt"
                              type="text"
                              name="beta1_usdt"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.beta1_usdt}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group>
                            <label>beta2_usdt</label>
                            <Form.Control
                              placeholder="beta2_usdt"
                              type="text"
                              name="beta2_usdt"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.beta2_usdt}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <Form.Group>
                            <label>{t('configParam.admin_num1')}</label>
                            <Form.Control
                              placeholder="admin_num1"
                              type="text"
                              name="admin_num1"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.admin_num1}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group>
                            <label>{t('configParam.admin_num1')}</label>
                            <Form.Control
                              placeholder="admin_num2"
                              type="text"
                              name="admin_num2"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.admin_num2}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group>
                            <label>{t('configParam.admin_num1')}</label>
                            <Form.Control
                              placeholder="admin_num3"
                              type="text"
                              name="admin_num3"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.admin_num3}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                    <Card.Footer>
                      <Row>
                        <Col md={6}>
                          <Button
                            className="btn-fill"
                            type="submit"
                            variant="info"
                          >
                            {t('common.update')}
                          </Button>
                        </Col>
                      </Row>
                    </Card.Footer>
                  </Card>
                </Form>
              )}
            </Formik>
          </Row>
        )}
      </Container>
    </>
  )
}
