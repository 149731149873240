const purchaseStatuses = Object.freeze({
    QUEUE: {id: "Q", title: "در صف"},
    FAILED: {id: "F", title: "خطا"},
    SUCCEED: {id: "S", title: "موفق"}
});

class PurchaseStatus
{
    getById(id){
        let selectedStatus =Object.entries(purchaseStatuses).find(value => value[1].id === id);
        return purchaseStatuses[selectedStatus[0]];
    }

    getValues(){
        return Object.keys(purchaseStatuses);
    }

}

export default new PurchaseStatus();
