import axios from 'axios'
import {
  BASE_CUSTOMER_PROFILE_URL,
  BASE_PORTAL_URL,
  BASE_TOKEN_URL,
  BASE_CUSTOMER_URL,
  BASE_APP_URL,
} from './urls'

axios.defaults.baseURL = BASE_PORTAL_URL
export const get = axios.get
export const post = axios.post
export const put = axios.put
export const patch = axios.patch
export const delet = axios.delete

export const tokenService = axios.create({
  baseURL: BASE_TOKEN_URL,
})

export const loadCustomerProfiles = data =>
  get(
    `${BASE_CUSTOMER_PROFILE_URL}list/?page=${data.page}&size=${data.size}&id=${data.id}
  &melli_code=${data?.melli_code}&name=${data?.name}
  &to_admin=${data?.to_admin}&sort_field=${data?.sort_field}&profile=${data?.profile}`,
  )

export const loadFullVerifiedCustomerProfiles = () =>
  get(`${BASE_CUSTOMER_PROFILE_UR}list/?ordering=-id&fully_verified=1`)
export const customerProfilesStatistic = () => get('customer/statistic/')
export const currencies = data =>
  get(
    `${BASE_PORTAL_URL}bb/currencies/?page=${data.page}&page_size=${data.size}`,
  )
export const verifyCustomerProfile = (id, data) =>
  put(`${BASE_CUSTOMER_PROFILE_UR}/verify/${id}/`, data)
export const verifyCustomerProfileDebit = (id, data) =>
  put(`${BASE_CUSTOMER_PROFILE_UR}/debit/verify/${id}/`, data)
export const loadUserDebits = userId =>
  get(`${BASE_CUSTOMER_PROFILE_URL}debit/add/?user=${userId}`)
export const loadUserWallets = userId =>
  get(`buy/customer-wallets/?user=${userId}`)
export const correctUserWallet = (buyId, wallet) =>
  put(`buy/walletcorrection/${buyId}/`, wallet)

export const loadPurchaseOrders = data =>
  get(`buy/list/?page=${data.page}
  &size=${data.size}
  &id=${data.id}
  &name=${data.user}
  &status=${data.status}
  &currency=${data.currency}
  &qty_now=${data.qty_now}
  &txid=${data.txid}
  &sort_field=${data.sort_field}`)
export const savePurchaseTransaction = (id, data) =>
  put(`buy/report/${id}/`, data)
export const invalidatePurchaseTransaction = (id, data) =>
  put(`buy/report/${id}/`, data)

export const createBestBuyTask = data => post(`bb/create-task/`, data)
export const loadBestBuyTasks = () => get(`bb/create-task/`)
export const reportBestBuyTask = (id, data) =>
  put(`bb/report-task/${id}/`, data)
export const saveBasket = data => post('bb/analysis/', data)
export const saveTestBasket = data => post('bb/test-basket', data)
export const loadBaskets = () => get('bb/analysis/')

export const loadSaleOrders = data =>
  get(`sell/list/?page=${data.page}
  &size=${data.size}
  &id=${data.id}
  &name=${data.user}
  &status=${data.status}
  &currency=${data.currency}
  &txid=${data.txid}
  &curr_amount=${data.curr_amount}
  &sort_field=${data.sort_field}`)
export const finalizeSaleOrder = (id, data) =>
  put(`sell/report/final/${id}/`, data)
export const calculateSaleOrderPrice = (id, data) =>
  put(`sell/report/trial/${id}/`, data)

export const loadDefaultSystemParams = id => get(`system/${id}/`)
export const updateSystemParams = (id, data) => put(`system/${id}/`, data)

export const saveSaleWallet = data => post('add_sitewallet/', data)
export const editSaleWallet = (id, data) => put(`sitewalletedit/${id}/`, data)
export const loadSaleWallets = () => get('sitewallet/')

export const loadWithdraws = () => get('withdraw/list/')
export const ModifyWithdrawStatus = (id, data) => put(`withdraw/${id}/`, data)
export const generateVandarToken = data => put('vandartoken/1/', data)

export const doLogin = data => tokenService.post('/login/', data)
export const loadRefreshToken = data =>
  tokenService.post('/refresh-token/', data)

export const getRemainingOrders = () => get('bb/smallchange/')
export const postRemainingOrders = data => post('bb/smallchange/', data)

//// currency

export const loadListCurrencies = data =>
  get(
    `${BASE_PORTAL_URL}exchange/currencies?page=${data.page}&size=${data.size}`,
  )
export const addCurrencies = data =>
  post(`${BASE_PORTAL_URL}exchange/currencies`, data)
export const getCurrencies = data =>
  get(`${BASE_PORTAL_URL}exchange/currencies`, data)
export const editCurrency = (id, data) =>
  patch(`${BASE_PORTAL_URL}exchange/currencies/${id}`, data)
export const deleteCurrency = id =>
  axios.delete(`${BASE_PORTAL_URL}exchange/currencies/${id}`)

//// portfolio currency

export const loadListPortfolioCurrency = data =>
  get(
    `${BASE_PORTAL_URL}bb/currencies/?page=${data.page}&page_size=${data.size}`,
  )
export const addPortfolioCurrencies = data =>
  post(`${BASE_PORTAL_URL}bb/currencies/`, data)
export const editPortfolioCurrency = (id, data) =>
  patch(`${BASE_PORTAL_URL}bb/currencies/${id}`, data)
export const deletePortfolioCurrency = id =>
  axios.delete(`${BASE_PORTAL_URL}bb/currencies/${id}`)

///network

export const loadListNetwork = data =>
  get(`${BASE_PORTAL_URL}exchange/networks?page=${data.page}&size=${data.size}`)
export const addNetwork = data =>
  post(`${BASE_PORTAL_URL}exchange/networks`, data)
export const editNetwork = data =>
  put(`${BASE_PORTAL_URL}exchange/networks/${data.id}`, data)
export const deleteNetwork = id =>
  axios.delete(`${BASE_PORTAL_URL}exchange/networks/${id}`)

///bestBuy baskets

export const loadBestBuyBasketList = data =>
  get(
    `bb/portfolio/?page=${data.page}&size=${data.size}&sort_field=${data.sort_field}`,
  )

export const loadBestBuyBasketHistory = data =>
  get(
    `/bb/portfolio/history/?user=${data.id}&page=${data.page}&size=${data.size}`,
  )

export const loadChargeRequestList = data =>
  get(`charge/list/?page=${data.page}
  &size=${data.size}
  &id=${data.id}
  &user=${data.user}
  &status=${data.status}
  &currency=${data.currency}
  &txid=${data.txid}
  &curr_amount=${data.curr_amount}
  &sort_field=${data.sort_field}`)

export const finalizeChargeOrder = (id, data) =>
  put(`charge/report/final/${id}/`, data)
export const calculateChargeOrderPrice = (id, data) =>
  patch(`charge/report/trial/${id}/`, data)

export const loadWithdrawRequestList = data =>
  get(`withdraw/list/?page=${data.page}
  &size=${data.size}
  &id=${data.id}
  &name=${data.user}
  &status=${data.status}
  &txid=${data.txid}
  &sort_field=${data.sort_field}`)

export const loadUserWithdrawWallets = userId =>
  get(`withdraw/customer-wallets/?user=${userId}`)
export const correctWithdrawtWallet = (withdrawId, wallet) =>
  put(`withdraw/walletcorrection/${withdrawId}/`, wallet)

export const saveWithdrawTransaction = (id, data) =>
  put(`withdraw/report/${id}/`, data)
export const invalidateWithdrawTransaction = (id, data) =>
  put(`withdraw/report/${id}/`, data)

///
export const getIncomeReport = data => {
  get(
    `${BASE_PORTAL_URL}report/income?page=${data.page}&size=${data.size}?start_date=${data?.start_date}&end_date=${data?.end_date}&action=${data?.action}&portfolio_owner_name=${data?.name}&ordering=${data?.ordering}`,
  )
}

export const getIncomePerUser = data =>
  get(
    `${BASE_PORTAL_URL}report/income?action=${data?.action}&report_type=sum_per_user`,
  )

export const getIncomeSum = data =>
  get(
    `${BASE_PORTAL_URL}report/income?action=${data?.action}&report_type=sum_for_all`,
  )
export const getIncomeReportCSV = data =>
  get(
    `${BASE_PORTAL_URL}report/income/csv?start_date=${data?.start_date}&end_date=${data?.end_date}&action=${data?.action}&portfolio_owner_name=${data?.name}&ordering=${data?.ordering}`,
  )
