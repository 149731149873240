import React, {useState} from 'react'
import {Card, Container, Row, Col, Modal, Form, Button} from 'react-bootstrap'
import {
  invalidateWithdrawTransaction,
  saveWithdrawTransaction,
} from '../../services/api'
import {useTranslation} from 'react-i18next'
import update from 'immutability-helper'

export default function WithdrawTransaction(props) {
  const [txid, setTxid] = useState()
  const [txidState, setTxidState] = useState(true)
  const {t} = useTranslation()
  const {purchaseId, onHide, purchases, setPurchaseOrders} = props

  const saveTxid = () => {
    if (!txid) {
      setTxidState(false)
      return
    }
    let transactionDto = {
      txid: txid,
      closed: true,
      failed: false,
      status: 'S',
    }
    saveWithdrawTransaction(purchaseId, transactionDto)
      .then(response => {
        updateModifiedRow(response)
        alert(t('common.success_message'))
        onHide()
      })
      .catch(err => alert(JSON.stringify(err)))
  }

  const invalidate = () => {
    let transactionDto = {
      failed: true,
      closed: true,
      status: 'F',
    }
    invalidateWithdrawTransaction(purchaseId, transactionDto)
      .then(response => {
        onHide()
        alert(JSON.stringify('Invalidate successfully'))
        updateModifiedRow(response)
      })
      .catch(err => alert(JSON.stringify(err)))
  }

  const updateModifiedRow = response => {
    let index = purchases.findIndex(item => item.id === purchaseId)
    let purchase = purchases[index]
    purchase.closed = response.data.closed
    purchase.failed = response.data.failed
    purchase.txid = response.data.txid
    let dataRows = update(purchases, {
      $splice: [[index, 1, purchase]],
    })
    setPurchaseOrders(dataRows)
  }

  return (
    <Modal
      size="lg"
      centered
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('buy.info')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Card>
          <Card.Body>
            <Card.Text>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <Form.Group
                      className={
                        'has-label ' + (txidState ? 'has-success' : 'has-error')
                      }
                    >
                      <label>
                        txid <span className="star">*</span>
                      </label>
                      <Form.Control
                        name="txid"
                        type="text"
                        value={txid}
                        onChange={e => {
                          setTxid(e.target.value)
                          if (!e.target.value) {
                            setTxidState(false)
                          } else setTxidState(true)
                        }}
                      ></Form.Control>
                      {txidState ? null : (
                        <label className="error">This field is required.</label>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Container>
            </Card.Text>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>{t('common.close')}</Button>
        <Button onClick={saveTxid}>{t('common.save')}</Button>
        <Button onClick={invalidate}>{t('buy.invalidate')}</Button>
      </Modal.Footer>
    </Modal>
  )
}
