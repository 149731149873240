import React, {useContext, useEffect, useState} from 'react'
import axios from 'axios'
import {doLogin, loadRefreshToken} from '../services/api'

const AuthContext = React.createContext()

function AuthContextProvider(props) {
  const [principal, setPrincipal] = useState(
    JSON.parse(localStorage.getItem('principal')),
  )
  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem('authenticated')),
  )

  const [refreshToken, setRefreshToken] = useState()

  useEffect(() => {
    const token = getToken()
    if (token) {
      setAuthHeader(token.token)
    } else {
      axios.defaults.auth = null
    }
  }, [])

  const setUserAuthenticationInfo = () => {
    /*        return new Promise((resolve, reject) => {
            SecurityService.loadCurrentUser()
                .then(response => {
                    console.log("loadCurrentUser", response.data);
                    let resPrincipal = response.data;
                    localStorage.setItem("principal", JSON.stringify(resPrincipal));
                    setPrincipal(resPrincipal);
                    resolve(resPrincipal);
                })
                .catch(err => reject(err));
        });*/
  }

  const login = (...args) => {
    const [credential, refreshToken] = args
    return new Promise((resolve, reject) => {
      if (refreshToken) {
        getRefreshToken(refreshToken)
      } else {
        doLogin(credential)
          .then(response => {
            setAuth(response.data)
            resolve(response)
          })
          .catch(err => reject(err))
      }
    })
  }

  const logout = () => {
    return new Promise(resolve => {
      setAuthenticated(false)
      localStorage.removeItem('authenticated')
      localStorage.removeItem('principal')
      localStorage.removeItem('sarrafeh-token')
      resolve(true)
    })
  }

  const setAuthHeader = accessToken => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
  }

  const getRefreshToken = refreshToken => {
    let refreshTokenDto = {refresh: refreshToken}
    loadRefreshToken(refreshTokenDto)
      .then(response => {
        setAuth(response.data)
        setRefreshToken(refreshToken)
      })
      .catch(err => console.log(err))
  }

  const setAuth = value => {
    setAuthenticated(true)
    localStorage.setItem('sarrafeh-token', JSON.stringify(value.token))
    localStorage.setItem('authenticated', JSON.stringify(true))
    setAuthHeader(value.token)
  }

  const getToken = () => {
    const tokenStore = localStorage.getItem('sarrafeh-token')
    return JSON.parse(tokenStore)
  }

  axios.interceptors.response.use(
    response => response,
    error => {
      const originalRequest = error.config
      if (error.response.status !== 401) {
        return Promise.reject(error)
      }
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true
        const token = getToken()
        if (token) {
          let refreshTokenDto = {refresh: token.refresh}
          return loadRefreshToken(refreshTokenDto).then(response => {
            setAuth(response.data)
            return axios(originalRequest)
          })
        }
      }
      return Promise.reject(error)
    },
  )

  axios.interceptors.request.use(
    config => {
      const token = getToken()
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
      }
      return config
    },
    error => {
      Promise.reject(error)
    },
  )

  return (
    <AuthContext.Provider
      value={{
        authPrincipal: principal,
        authenticated: authenticated,
        login: login,
        logout: logout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

const useAuth = () => useContext(AuthContext)

export {AuthContextProvider, useAuth}
