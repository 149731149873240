import React from 'react'
import Button from 'react-bootstrap/Button'
import {Container, Form} from 'react-bootstrap'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
import {useTranslation} from 'react-i18next'
import YesNo from '../../enums/YesNo'

export default function BasketHistoryDetail(props) {
  const {t} = useTranslation()
  const {history, onHide} = props

  return (
    <Modal
      size="lg"
      centered
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('bestBuy.history.title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Card>
          <Card.Body>
            <Card.Text>
              <Col xs={4} md={4}>
                <div>
                  <h6>{t('common.id')}</h6>
                  <p className="category">{history.id}</p>
                </div>
              </Col>
              <Col xs={12} md={12}>
                <div>
                  <h6>{t('bestBuy.history.must_be')}</h6>
                  <p className="category">{JSON.stringify(history?.must_be)}</p>
                </div>
              </Col>
              <Col xs={12} md={12}>
                <div>
                  <h6>{t('bestBuy.history.variation')}</h6>
                  <p className="category">
                    {JSON.stringify(history?.variation)}
                  </p>
                </div>
              </Col>
            </Card.Text>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>{t('common.close')}</Button>
      </Modal.Footer>
    </Modal>
  )
}
