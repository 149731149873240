import React, {useEffect, useMemo, useState} from 'react'
import {Container, Row, Col, Card, Button} from 'react-bootstrap'
import ReactTable from '../../components/ReactTable/ReactTable'
import {FormGroup, Input} from 'reactstrap'
import Select from 'react-select'
import {loadPurchaseOrders} from '../../services/api'
import PurchaseTransaction from './PurchaseTransaction'
import {useTranslation} from 'react-i18next'
import PurchaseStatus from '../../enums/PurchaseStatus'
import YesNo from '../../enums/YesNo'
import PurchaseDetail from './PurchaseDetail'
import AssignedWallet from '../Sales/AssignedWallet'

export default function PurchaseOrders(props) {
  const {t} = useTranslation()

  const purchaseColumns = [
    {
      id: 'tracker_code',
      Header: t('common.tracker_code'),
      accessor: d => d.buy.tracker_code,
      maxWidth: 900,
      minWidth: 10,
      flexGrow: 1,
      width: '100%',
      style: {overflowWrap: 'break-word'},
      Footer: () => {
        return (
          <>
            {t('table.countRows')} {count}
          </>
        )
      },
    },
    {
      Header: t('common.customer_name'),
      accessor: d => `${d.customer.name} `,
      flexGrow: 1,
      width: '10%',
      style: {'white-space': 'unset'},
    },
    {
      Header: t('buy.table.amount'),
      accessor: d =>
        d.buy.amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
      minWidth: 300,
    },
    {
      Header: t('buy.table.currency'),
      accessor: d => d.buy.currency,
    },
    {
      Header: t('buy.table.qty_now'),
      accessor: d => d.buy.qty_now,
    },
    {
      id: 'closed',
      Header: t('buy.table.closed'),
      accessor: d => YesNo.getById(d.buy.closed).title,
    },
    {
      id: 'failed',
      Header: t('buy.table.failed'),
      accessor: d => YesNo.getById(d.buy.failed).title,
    },
    {
      Header: 'txid',
      accessor: d => d.buy.txid,
    },
    {
      Header: t('common.status'),
      accessor: d => PurchaseStatus.getById(d.buy.status).title,
    },
    {
      Header: t('common.actions'),
      accessor: 'actions',
      sortable: false,
      filterable: false,
    },
  ]
  const [purchaseOrders, setPurchaseOrders] = useState([])
  const [showTransactionDialog, setShowTransactionDialog] = useState(false)
  const [showDetailDialog, setShowDetailDialog] = useState(false)
  const [showWalletDialog, setShowWalletDialog] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState()
  const [assignedWallet, setAssignedWallet] = useState()
  const [personProperty, setPersonProperty] = useState({})
  const [count, setCount] = useState(0)
  const [next, setNext] = useState('')
  const [user, setUser] = useState('')
  const [id, setId] = useState('')
  const [status, setStatus] = useState('')
  const [currency, setCurrency] = useState('')
  const [qty_now, setQty_now] = useState('')
  const [txid, setTxid] = useState('')
  const [loading, setLoading] = useState(false)
  const [sort_field, setSort_field] = useState('-last_update')

  const statusOption = [
    {label: 'در صف', value: 'Q'},
    {label: 'خطا', value: 'F'},
    {label: 'موفق', value: 'S'},
  ]

  const fetchData = (page, size) => {
    if (page > 0 && size > 0) {
      const data = {
        page: page,
        size: size,
        id: id,
        user: user,
        status: status,
        currency: currency,
        sort_field: sort_field,
        qty_now: qty_now,
        txid: txid,
      }
      setLoading(true)
      loadPurchaseOrders(data)
        .then(response => {
          setPurchaseOrders(
            response.data.results
              .map((prop, key) => {
                return {
                  ...prop,
                  actions: (
                    <div className="actions-right">
                      <Button
                        onClick={() => {
                          setAssignedWallet('')
                          let wallet = prop.buy.wallet
                          setAssignedWallet(wallet)
                          wallet.buyId = prop.buy.id
                          setPersonProperty({
                            qty_now: prop.buy.qty_now,
                            tracker_code: prop.buy.tracker_code,
                          })

                          setShowWalletDialog(true)
                        }}
                        variant="primary"
                        size="sm"
                        className="btn-link like"
                      >
                        <i className="fa fa-wallet" />
                      </Button>
                      <Button
                        onClick={() => {
                          setSelectedOrder(prop)
                          setShowDetailDialog(true)
                        }}
                        variant="info"
                        size="sm"
                        className="text-info btn-link like"
                      >
                        <i className="fa fa-eye" />
                      </Button>
                      <Button
                        onClick={() => {
                          setSelectedOrder(prop)
                          setShowTransactionDialog(true)
                        }}
                        variant="warning"
                        size="sm"
                        className="text-warning btn-link edit"
                      >
                        <i className="fa fa-edit" />
                      </Button>
                    </div>
                  ),
                }
              })
              .sort((a, b) => (a.buy.id > b.buy.id ? -1 : 1)),
          )
          setCount(response.data.count)
          setNext(response.data.next)
          setLoading(false)
        })
        .catch(err => {
          alert('عملیات با خطا مواجه شد')
          alert(JSON.stringify(err))
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    setAssignedWallet('')
    let timeout = null
    if (!timeout) {
      timeout = setTimeout(() => {
        fetchData(1, 10)
      }, 500)
    }

    return () => clearTimeout(timeout)
  }, [user, status, currency, sort_field, id, qty_now, txid])

  return (
    <>
      <Container fluid>
        {selectedOrder && (
          <PurchaseDetail
            purchase={selectedOrder.buy}
            show={showDetailDialog}
            onHide={() => setShowDetailDialog(false)}
          />
        )}
        {selectedOrder && (
          <PurchaseTransaction
            purchaseId={selectedOrder.buy.id}
            show={showTransactionDialog}
            purchases={purchaseOrders}
            setPurchaseOrders={setPurchaseOrders}
            onHide={() => setShowTransactionDialog(false)}
          />
        )}

        {assignedWallet && (
          <AssignedWallet
            wallet={assignedWallet}
            personProperty={personProperty}
            show={showWalletDialog}
            onHide={() => setShowWalletDialog(false)}
          />
        )}
        <Row>
          <Col md="12">
            <Card>
              <div className="filter-area">
                <Col md="4">
                  <FormGroup>
                    <label>نام مشتری</label>
                    <Input
                      name="user"
                      placeholder="user"
                      type="text"
                      onChange={e => {
                        setUser(e.target.value || '')
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label>وضعیت</label>
                    <Select
                      name="status"
                      options={statusOption}
                      palceholder="status"
                      onChange={event => {
                        setStatus(event.value)
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label>ارز</label>
                    <Input
                      name="currency"
                      placeholder="currency"
                      type="text"
                      onChange={e => {
                        setCurrency(e.target.value || '')
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col md="4">
                  <FormGroup>
                    <label>شناسه</label>
                    <Input
                      name="id"
                      placeholder="id"
                      type="text"
                      onChange={e => {
                        setId(e.target.value || '')
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col md="4">
                  <FormGroup>
                    <label> تعداد کنونی </label>
                    <Input
                      name="qty_now"
                      placeholder="qty_now"
                      type="text"
                      onChange={e => {
                        setQty_now(e.target.value || '')
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label>txid</label>
                    <Input
                      name="txid"
                      placeholder="txid"
                      type="text"
                      onChange={e => {
                        setTxid(e.target.value || '')
                      }}
                    />
                  </FormGroup>
                </Col>
              </div>

              <Card.Body>
                <ReactTable
                  data={purchaseOrders}
                  count={count}
                  next={next}
                  columns={purchaseColumns}
                  className="-striped -highlight primary-pagination"
                  fetchingData={(pageIndex, pageSize) =>
                    fetchData(pageIndex, pageSize)
                  }
                  loading={loading}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}
