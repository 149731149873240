import React from 'react'
import {Switch, Route} from 'react-router-dom'
// react-bootstrap components
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Pagination,
  Container,
  Row,
  Col,
} from 'react-bootstrap'

// core components
import Sidebar from 'components/Sidebar/Sidebar.js'
import AdminNavbar from 'components/Navbars/AdminNavbar.js'
import AdminFooter from 'components/Footers/AdminFooter.js'

// dinamically create dashboard routes
import routes from 'routes.js'

import image3 from 'assets/img/full-screen-image-3.jpg'
import {useTranslation} from 'react-i18next'

function Admin(props) {
  const [sidebarImage, setSidebarImage] = React.useState(image3)
  const [sidebarBackground, setSidebarBackground] = React.useState('black')

  // const routes = adminRoutes(t);

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views)
      }
      if (prop.layout === '/admin') {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            component={prop.component}
          />
        )
      } else {
        return null
      }
    })
  }
  return (
    <>
      <div className="wrapper">
        <Sidebar
          routes={routes}
          image={sidebarImage}
          background={sidebarBackground}
        />
        <div className="main-panel">
          <AdminNavbar {...props} />
          <div className="content">
            <Switch>{getRoutes(routes)}</Switch>
          </div>
          <AdminFooter />
          <div
            className="close-layer"
            onClick={() =>
              document.documentElement.classList.toggle('nav-open')
            }
          />
        </div>
      </div>
    </>
  )
}

export default Admin
