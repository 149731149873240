import React, {useEffect, useState} from 'react'
import {
  editSaleWallet,
  loadListNetwork,
  loadListCurrencies,
  getCurrencies,
} from '../../services/api'
import {Button, Container, Form} from 'react-bootstrap'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import {useTranslation} from 'react-i18next'
import {Formik} from 'formik'
import Select from 'react-select'

export default function EditSaleWallet(props) {
  const {t} = useTranslation()
  const {onClose, setWallets, selectedWallet, editStateWallet, listCurrencies} =
    props
  console.log(selectedWallet, 'selectedWallet')
  let wallet = {
    id: selectedWallet.id,
    name: selectedWallet.name,
    address: selectedWallet.address,
    dist_tag: selectedWallet.dist_tag,
    currency: selectedWallet.currency,
    active: selectedWallet.active,
    qr_code: selectedWallet.qr_code,
  }
  const [listNetwork, setListNetwork] = useState({data: []})

  const activeStatuse = [
    {
      label: 'غیر فعال',
      value: false,
    },
    {
      label: 'فعال',
      value: true,
    },
  ]
  const getLabelByValue = (Array, values) => {
    if (values) {
      let object = Array.find(obj => obj.value === values)
      return object.label
    }
  }
  return (
    <Container fluid>
      <Formik
        initialValues={wallet}
        onSubmit={values => {
          const formData = new FormData()

          formData.append('name', JSON.stringify(values.name))
          formData.append('address', values?.address)
          formData.append('currency', values?.currency)
          formData.append('dist_tag', values?.dist_tag)
          formData.append('active', values?.active)
          values.qr_code && formData.append('qr_code', values?.qr_code)
          formData.append('id', values?.id)
          const id = values.id
          editSaleWallet(id, formData)
            .then(response => {
              alert('عملیات با موفقیت انجام شد')
              setWallets(prev => [...prev, response.data])
              onClose()
            })
            .catch(err => alert(JSON.stringify(err.response.data)))
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Card className="stacked-form">
              <Card.Header>
                <Card.Title as="h4">{t('saleWallet.edit')}</Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md={6}>
                    <Form.Group>
                      <label>{t('common.name')}</label>
                      <Form.Control
                        placeholder={t('common.name')}
                        type="text"
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <label>{t('common.address')}</label>
                      <Form.Control
                        placeholder={t('common.address')}
                        type="text"
                        name="address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.address}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group required>
                      <label>{t('common.currency')}</label>
                      <Select
                        name="currency"
                        options={listCurrencies.data}
                        palceholder="currency"
                        onChange={event =>
                          setFieldValue('currency', event.value)
                        }
                        onBlur={setFieldTouched}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <label>{t('common.dist_tag')}</label>
                      <Form.Control
                        placeholder={t('common.dist_tag')}
                        type="text"
                        name="dist_tag"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.dist_tag}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <label>{t('saleWallet.qr-code')}</label>
                    <Form.Control
                      type="file"
                      name="qr_code"
                      accept="image/*"
                      onChange={event =>
                        setFieldValue('qr_code', event.target.files[0])
                      }
                      onBlur={handleBlur}
                    ></Form.Control>
                  </Col>
                  <Col md={6}>
                    <Form.Group required>
                      <label> {t('common.active')}</label>
                      <Select
                        name="active"
                        options={activeStatuse}
                        palceholder="active"
                        onChange={event => {
                          setFieldValue('active', event.value)
                        }}
                        onBlur={setFieldTouched}
                        value={{
                          value: values.active,
                          label: getLabelByValue(activeStatuse, values?.active),
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <Row>
                  <Col md={1}>
                    <Button className="btn-fill" type="submit" variant="info">
                      {t('common.save')}
                    </Button>
                  </Col>
                  <Col md={1}>
                    <Button
                      className="btn-fill"
                      onClick={onClose}
                      variant="info"
                    >
                      {t('common.close')}
                    </Button>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Form>
        )}
      </Formik>
    </Container>
  )
}
