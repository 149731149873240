import React, {useEffect, useState} from 'react'
import {Button, Container, Form, Row, Col, Card, Modal} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import Active from '../../enums/Active'
import Network from '../../enums/Network'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {
  correctWithdrawtWallet,
  loadUserWithdrawWallets,
} from '../../services/api'
import Select from 'react-select'
import Currency from '../../enums/Currency'

export default function AssignedWallet(props) {
  const {t} = useTranslation()
  const {wallet} = props
  const [isCopied, setIsCopied] = useState(false)
  const [userWallets, setUserWallets] = useState([])
  const [selectedWallet, setSelectedWallet] = useState()

  const handleClose = () => {
    setUserWallets([])
    props.onHide()
  }

  const updateUserWallet = () => {
    let walletDto = {wallet: selectedWallet.value.id}
    correctWithdrawtWallet(wallet.buyId, walletDto)
      .then(() => {
        alert(t('common.success_message'))
      })
      .catch(err => alert(err))
    handleClose()
  }

  const onCopyText = () => {
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 1000)
  }

  useEffect(() => {
    loadUserWithdrawWallets(wallet.user)
      .then(response => {
        let wallets = response.data
          .filter(record => !record.deleted)
          .map(item => {
            let dto = {
              value: item,
              label: `${item.address} - ${item.currency} - ${item.network}`,
            }
            return dto
          })
        setUserWallets(wallets)
      })
      .catch(err => alert(err))
  }, [wallet])

  return (
    <Modal
      size="lg"
      centered
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('buy.wallet.info')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Card>
          <Card.Body>
            <Card.Text>
              <Container>
                <Row>
                  <Col xs={12} md={10}>
                    <div>
                      <h6>{t('common.address')}</h6>
                      <div style={{display: 'flex'}}>
                        <p className="category">{wallet.address}</p>
                        <CopyToClipboard
                          text={wallet.address}
                          onCopy={onCopyText}
                        >
                          <div>
                            <Button
                              variant="info"
                              size="sm"
                              className="text-info btn-link like"
                            >
                              <i className="fa fa-copy" />
                            </Button>
                          </div>
                        </CopyToClipboard>

                        <div>{isCopied && <span>کپی شد</span>}</div>
                      </div>
                    </div>
                  </Col>
                  {wallet.active && (
                    <Col xs={12} md={2}>
                      {console.log(wallet, 'wallet')}
                      <div>
                        <h6>{t('common.active')}</h6>
                        <p className="category">
                          {Active.getById(wallet.active).title}
                        </p>
                      </div>
                    </Col>
                  )}
                </Row>

                <Row>
                  <Col xs={12} md={12}>
                    <Form.Group>
                      <label>{t('common.userWallets')}</label>
                      <Select
                        name="userWallets"
                        value={selectedWallet}
                        options={userWallets}
                        placeholder="currency"
                        onChange={e => setSelectedWallet(e)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} md={6}>
                    <div>
                      <h6>{t('common.currency')}</h6>
                      <p className="category">{wallet.currency.symbol}</p>
                    </div>
                  </Col>
                  <Col xs={6} md={6}>
                    <div>
                      <h6>{t('common.id')}</h6>
                      <p className="category">{wallet.id}</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} md={6}>
                    <div>
                      <h6>{t('common.name')}</h6>
                      <p className="category">{wallet.name}</p>
                    </div>
                  </Col>
                  <Col xs={6} md={6}>
                    <div>
                      <h6>{t('common.network')}</h6>
                      <p className="category">{wallet.currency.network}</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} md={6}>
                    <div>
                      <h6>{t('common.dist_tag')}</h6>
                      <p className="category">{wallet.dist_tag}</p>
                    </div>
                  </Col>
                  <Col xs={6} md={6}>
                    <div>
                      <h6>{t('common.user')}</h6>
                      <p className="category">{wallet.user}</p>
                    </div>
                  </Col>
                  <Col xs={6} md={6}>
                    <div>
                      <h6>{t('buy.table.qty_now')}</h6>
                      <p className="category">{props.personProperty.qty_now}</p>
                    </div>
                  </Col>
                  <Col xs={6} md={6}>
                    <div>
                      <h6>{t('common.tracker_code')}</h6>
                      <p className="category">
                        {props.personProperty.tracker_code}
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Card.Text>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>{t('common.close')}</Button>
        <Button onClick={updateUserWallet}>
          {t('buy.wallet.updateWallet')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
