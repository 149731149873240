import React, { useEffect, useState } from "react";
import { loadUserDebits } from "../../services/api";
import Debit from "./Debit";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function VerifyDebits(props) {
  const [customerDebits, setCustomerDebits] = useState([]);
  const { userId, setDebitVerifications, debitVerifications } = props;

  useEffect(() => {
    loadUserDebits(userId)
      .then((response) => {
        setCustomerDebits(response.data.results);
        setDebitVerifications(
          response.data.results.map((item) => ({
            id: item.id,
            verified: item.verified,
          }))
        );
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col xs={12} md={12}>
            {customerDebits.map((debit) => (
              <Debit
                key={debit.id}
                debit={debit}
                setDebitVerifications={setDebitVerifications}
                debitVerifications={debitVerifications}
              />
            ))}
          </Col>
        </Row>
      </Container>
    </>
  );
}
