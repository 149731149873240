import React from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import { addNetwork } from "../../services/api";
import { useTranslation } from "react-i18next";

export default function AddNetwork(props) {
  const { t } = useTranslation();
  const { onClose, addNewNetwok } = props;
  let basket = {
    name: { fa: "" },
    symbol: "",
  };

  return (
    <>
      <Container fluid>
        <Row sm={1} md={2}>
          <Formik
            initialValues={basket}
            onSubmit={(values) => {
              addNetwork(values)
                .then((response) => {
                  alert("عملیات با موفقیت انجام شد");
                  addNewNetwok((prev) => [
                    ...prev,
                    { ...response.data, name: response.data.name.fa },
                  ]);
                  onClose();
                })
                .catch((err) => alert(JSON.stringify(err)));
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Card className="stacked-form">
                  <Card.Header>
                    <Card.Title as="h4">{t("network.add")}</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Form.Group>
                      <label>{t("network.name")}</label>
                      <Form.Control
                        placeholder="name"
                        type="text"
                        name="name"
                        onChange={(event) =>
                          setFieldValue("name", { fa: event.target.value })
                        }
                        onBlur={handleBlur}
                        value={values.name.fa}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group>
                      <label>{t("network.symbol")}</label>
                      <Form.Control
                        placeholder="symbol"
                        type="text"
                        name="symbol"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.symbol}
                      ></Form.Control>
                    </Form.Group>
                  </Card.Body>
                  <Card.Footer>
                    <Row>
                      <Col md={2} className="ml-3">
                        <Button
                          className="btn-fill"
                          type="submit"
                          variant="info"
                        >
                          {t("common.save")}
                        </Button>
                      </Col>
                      <Col md={2}>
                        <Button
                          className="btn-fill"
                          onClick={onClose}
                          variant="info"
                        >
                          {t("common.close")}
                        </Button>
                      </Col>
                    </Row>
                  </Card.Footer>
                </Card>
              </Form>
            )}
          </Formik>
        </Row>
      </Container>
    </>
  );
}
