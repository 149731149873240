const withdrawStatuses = Object.freeze({
    QUEUE: {id: "Q", title: "در صف"},
    VERIFIED: {id: "V", title: "تایید شده"},
    REJECTED: {id: "R", title: "رد شده"}
});

class WithdrawRequestStatus
{
    getById(id){
        let selectedStatus =Object.entries(withdrawStatuses).find(value => value[1].id === id);
        return withdrawStatuses[selectedStatus[0]];
    }

    getValues(){
        return Object.keys(withdrawStatuses);
    }

}

export default new WithdrawRequestStatus();
