import React, {useEffect, useState} from 'react'
import {Button, Card, Col, Container, Form, Row} from 'react-bootstrap'
import {Formik} from 'formik'
import {editCurrency} from '../../services/api'
import {useTranslation} from 'react-i18next'
import Select from 'react-select'
import {
  saveSaleWallet,
  loadListNetwork,
  loadListCurrencies,
} from '../../services/api'

export default function EditCurrency(props) {
  const {t} = useTranslation()
  const {onClose, selectedCurrency, editStateCurrencies} = props

  let basket = {
    name: {fa: selectedCurrency.name.fa},
    symbol: selectedCurrency.symbol,
    network: selectedCurrency.network,
    sarrafeh_buy_fee: selectedCurrency.sarrafeh_buy_fee,
    sarrafeh_sell_fee: selectedCurrency.sarrafeh_sell_fee,
    icon: selectedCurrency.icon,
    active_buy: selectedCurrency.active_buy,
    active_sell: selectedCurrency.active_sell,
    order: selectedCurrency.order,
    need_tag: selectedCurrency.need_tag,
    id: selectedCurrency?.id,
  }

  const [listNetwork, setListNetwork] = useState({data: []})
  const [icon, setIcon] = useState(false)

  const activeCurrencyList = [
    {
      label: 'فعال',
      value: true,
    },
    {
      label: 'غیر فعال',
      value: false,
    },
  ]
  const needTagList = [
    {
      label: 'دارد',
      value: true,
    },
    {
      label: 'ندارد',
      value: false,
    },
  ]

  const getLabelByValue = (Array, values) => {
    let object = Array.find(obj => obj.value === values)
    return object.label
  }

  useEffect(() => {
    loadListNetwork({
      page: 1,
      size: 100,
    })
      .then(response => {
        response.data.results.map(item => {
          let flagDta = {}
          flagDta.label = `${item.name.fa} - ${item.symbol}`
          flagDta.value = item.id

          listNetwork.data.push(flagDta)
          setListNetwork({...listNetwork})
        })
      })
      .catch(err => alert(JSON.stringify(err)))
  }, [])

  return (
    <>
      <Container fluid>
        <Row sm={1} md={2}>
          <Formik
            initialValues={basket}
            onSubmit={values => {
              const formData = new FormData()
              formData.append('id', values.id)
              formData.append('symbol', values.symbol)
              formData.append('sarrafeh_buy_fee', values.sarrafeh_buy_fee)
              formData.append('sarrafeh_sell_fee', values.sarrafeh_sell_fee)
              formData.append('name', JSON.stringify(values.name))
              formData.append('network', values.network)
              formData.append('active_buy', values.active_buy)
              formData.append('active_sell', values.active_sell)
              formData.append('order', values.order)
              formData.append('need_tag', values.need_tag)
              icon && formData.append('icon', values.icon)
              values.webp_icon && formData.append('webp_icon', values.webp_icon)
              const id = values.id
              editCurrency(id, formData)
                .then(response => {
                  alert('عملیات با موفقیت انجام شد')
                  editStateCurrencies(prev => {
                    let array = prev
                    const editedData = array.find(
                      item => item.id === response.data.id,
                    )
                    array = array.filter(item => item !== editedData)

                    const newArray = [
                      ...array,
                      {
                        ...response.data,
                        name: response.data.name.fa,
                        actions: editedData.actions,
                      },
                    ]

                    return newArray
                  })
                })
                .catch(err => {
                  alert(JSON.stringify(err))
                })
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Card className="stacked-form">
                  <Card.Header>
                    <Card.Title as="h4">{t('currency.edit')}</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Form.Group>
                      <label>{t('currency.symbol')}</label>
                      <Form.Control
                        placeholder="symbol"
                        type="text"
                        name="symbol"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.symbol}
                      ></Form.Control>
                      <label>{t('currency.name')}</label>
                      <Form.Control
                        placeholder="name"
                        type="text"
                        name="name"
                        onChange={event =>
                          setFieldValue('name', {fa: event.target.value})
                        }
                        onBlur={handleBlur}
                        value={values.name.fa}
                      ></Form.Control>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div>
                          <label>فی خرید</label>

                          <Form.Control
                            placeholder="sarrafeh_buy_fee"
                            type="number"
                            step="0.0001"
                            name="sarrafeh_buy_fee"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.sarrafeh_buy_fee}
                          ></Form.Control>
                        </div>
                        <div>
                          <label>beta</label>

                          <Form.Control
                            placeholder="sarrafeh_sell_fee"
                            type="number"
                            step="0.0001"
                            name="sarrafeh_sell_fee"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.sarrafeh_sell_fee}
                          ></Form.Control>
                        </div>
                      </div>

                      <label>{t('currency.network')}</label>
                      {listNetwork.data.length > 0 && (
                        <Select
                          name="network"
                          options={listNetwork.data}
                          placeholder="network"
                          onChange={event => {
                            setFieldValue('network', event.value)
                          }}
                          onBlur={setFieldTouched}
                          // value={{
                          //   value: values.network,
                          //   label: getLabelByValue(
                          //     listNetwork.data,
                          //     values.network,
                          //   ),
                          // }}
                        />
                      )}

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div
                          style={{
                            flexBasis: '49.5%',
                          }}
                        >
                          <label>{t('currency.active_buy')}</label>
                          <Select
                            name="active_buy"
                            options={activeCurrencyList}
                            palceholder="active_buy"
                            onChange={event => {
                              setFieldValue('active_buy', event.value)
                            }}
                            onBlur={setFieldTouched}
                            value={{
                              value: values.active_buy,
                              label: getLabelByValue(
                                activeCurrencyList,
                                values.active_buy,
                              ),
                            }}
                          />
                        </div>

                        <div
                          style={{
                            flexBasis: '50%',
                          }}
                        >
                          {' '}
                          <label>{t('currency.active_sell')}</label>
                          <Select
                            name="active_sell"
                            options={activeCurrencyList}
                            palceholder="active_sell"
                            onChange={event => {
                              setFieldValue('active_sell', event.value)
                            }}
                            onBlur={setFieldTouched}
                            value={{
                              value: values.active_sell,
                              label: getLabelByValue(
                                activeCurrencyList,
                                values.active_sell,
                              ),
                            }}
                          />
                        </div>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div
                          style={{
                            flexBasis: '50%',
                          }}
                        >
                          <label>{t('currency.order')}</label>

                          <Form.Control
                            placeholder={t('currency.order')}
                            type="number"
                            step="1"
                            name="order"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.order}
                          ></Form.Control>
                        </div>
                        <div
                          style={{
                            flexBasis: '49.5%',
                          }}
                        >
                          <label>{t('currency.need_tag')}</label>
                          <Select
                            name="need_tag"
                            options={needTagList}
                            palceholder={t('currency.need_tag')}
                            onChange={event => {
                              setFieldValue('need_tag', event.value)
                            }}
                            onBlur={setFieldTouched}
                            value={{
                              value: values.need_tag,
                              label: getLabelByValue(
                                needTagList,
                                values.need_tag,
                              ),
                            }}
                          />
                        </div>
                      </div>

                      <label>{t('currency.icon')}</label>
                      <Form.Control
                        type="file"
                        name="icon"
                        accept="image/*"
                        onChange={event => {
                          setFieldValue('icon', event.target.files[0])
                          if (event.target.files[0]) {
                            setIcon(true)
                          } else {
                            setIcon(false)
                          }
                        }}
                        onBlur={handleBlur}
                      ></Form.Control>

                      <label>{t('currency.iconWebp')}</label>
                      <Form.Control
                        type="file"
                        name="webp_icon"
                        accept="image/webp"
                        onChange={event =>
                          setFieldValue('webp_icon', event.target.files[0])
                        }
                        onBlur={handleBlur}
                      ></Form.Control>
                    </Form.Group>
                  </Card.Body>
                  <Card.Footer>
                    <Row>
                      <Col md={2} className="ml-3">
                        <Button
                          className="btn-fill"
                          type="submit"
                          variant="info"
                        >
                          {t('currency.edit')}
                        </Button>
                      </Col>
                      <Col md={2}>
                        <Button
                          className="btn-fill"
                          onClick={onClose}
                          variant="info"
                        >
                          {t('common.close')}
                        </Button>
                      </Col>
                    </Row>
                  </Card.Footer>
                </Card>
              </Form>
            )}
          </Formik>
        </Row>
      </Container>
    </>
  )
}
