import React, {useState, useEffect, useMemo} from 'react'
import {Button, Card, Col, Container, Row} from 'react-bootstrap'
import {
  loadListPortfolioCurrency,
  deletePortfolioCurrency,
} from '../../../services/api'
import {useTranslation} from 'react-i18next'
import ReactTable from '../../../components/ReactTable/ReactTable'
import AddPortfolioCurrency from './AddPortfolioCurrency'
import EditPortfolioCurrency from './EditPortfolioCurrency'

export default function PortfolioCurrency(props) {
  const {t} = useTranslation()
  const [listPortfolioCurrency, setListPortfolioCurrency] = useState([])
  const [showAddCurrency, setShowAddCurrency] = useState(false)
  const [showEditCurrency, setShowEditCurrency] = useState(false)
  const [selectedCurrency, setSelectedCurrency] = useState(null)
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState(0)
  const [next, setNext] = useState('')

  const portfolioColumns = [
    {
      Header: t('common.id'),
      accessor: 'id',
      Footer: () => {
        return (
          <>
            {t('table.countRows')} {count}
          </>
        )
      },
    },
    {
      Header: 'binance_pair_symbol',
      accessor: 'binance_pair_symbol',
      sortable: false,
      filterable: false,
    },
    {
      Header: t('currency.name'),
      accessor: d => d.name.en,
    },
    {
      Header: t('currency.symbol'),
      accessor: 'symbol',
    },
    {
      Header: 'max_digits',
      accessor: 'max_digits',
    },
    {
      Header: 'decimal_point',
      accessor: 'decimal_point',
    },
    {
      Header: 'logo',
      accessor: 'logo',
    },
    {
      Header: t('common.actions'),
      accessor: 'actions',
    },
  ]

  const setSelectedvalue = value => {
    setSelectedCurrency(value)
  }
  const deletevalue = value => {
    deletePortfolioCurrency(value.id)
      .then(response => {
        alert('عملیات با موفقیت انجام شد')
        loadNewListPortfolioCurrency({page: 1, size: 10})
      })
      .catch(err => {
        alert(JSON.stringify(err))
      })
  }

  const loadNewListPortfolioCurrency = data => {
    setLoading(true)
    loadListPortfolioCurrency(data)
      .then(response => {
        setListPortfolioCurrency(
          response.data.results.map(prop => {
            return {
              ...prop,
              icon: (
                <img src={prop.icon} alt={prop.symbol} width="30" height="30" />
              ),
              name: prop.name.fa,
              actions: (
                <div className="actions-right">
                  <Button
                    onClick={() => {
                      setSelectedvalue(prop)
                      setShowEditCurrency(true)
                    }}
                    variant="warning"
                    size="sm"
                    className="text-warning btn-link edit"
                  >
                    <i className="fa fa-edit" />
                  </Button>
                  <Button
                    onClick={() => {
                      if (confirm('آیا از انجام این عملیات اطمینان دارید؟')) {
                        setSelectedvalue(prop)
                        deletevalue(prop)
                      } else {
                        // Do nothing!
                      }
                    }}
                    variant="danger"
                    size="sm"
                    className="text-danger btn-link delete"
                  >
                    <i className="fa fa-trash" />
                  </Button>
                </div>
              ),
            }
          }),
        )
        setLoading(false)
        setCount(response.data.count)
        setNext(response.data.next)
      })
      .catch(err => {
        alert('عملیات با خطا مواجه شد')
        alert(JSON.stringify(err))
        setLoading(false)
      })
  }

  const fetchData = (page, size) => {
    if (page > 0 && size > 0) {
      const data = {
        page: page,
        size: size,
      }
      loadNewListPortfolioCurrency(data)
    }
  }

  useEffect(() => {
    fetchData(1, 10)
  }, [count])

  return (
    <>
      <Container fluid>
        {!showAddCurrency && (
          <Button
            className="btn-fill"
            variant="info"
            onClick={e => setShowAddCurrency(true)}
          >
            + {t('currency.add')}
          </Button>
        )}
        {showAddCurrency && (
          <AddPortfolioCurrency
            addNewCurrency={setListPortfolioCurrency}
            onClose={e => {
              setShowAddCurrency(false)
            }}
          />
        )}
        {showEditCurrency && (
          <EditPortfolioCurrency
            editStateCurrencies={setListPortfolioCurrency}
            onClose={e => {
              setShowEditCurrency(false)
              loadNewListPortfolioCurrency({page: 1, size: 10})
            }}
            selectedCurrency={selectedCurrency}
          />
        )}
        <Row>
          <Col md="12">
            <Card>
              <Card.Body>
                <ReactTable
                  data={listPortfolioCurrency}
                  count={count}
                  next={next}
                  columns={portfolioColumns}
                  className="-striped -highlight primary-pagination"
                  fetchingData={(pageIndex, pageSize) =>
                    fetchData(pageIndex, pageSize)
                  }
                  loading={loading}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}
