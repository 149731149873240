import moment from 'moment-jalaali';

class DateUtil{

    convertToPersian(dateTime){
        if (dateTime)
            return moment(dateTime.toLocaleString()).format('jYYYY/jMM/jDD HH:mm');
    }

    convertToJalaliBirthDate(date){
        if (date)
            return moment(date.toString(), 'YYYY-MM-DD').format('jYYYY/jMM/jDD');
    }

}

export default new DateUtil();