const bestBuyActions = Object.freeze({
  QUEUE: {id: 'R', title: 'بهینه سازی'},
  FAILED: {id: 'A', title: 'افزایش سرمایه'},
  SUCCEED: {id: 'S', title: 'فروش'},
})

class BestBuyActions {
  getById(id) {
    let selectedStatus = Object.entries(bestBuyActions).find(
      value => value[1].id === id,
    )
    return bestBuyActions[selectedStatus[0]]
  }

  getValues() {
    return Object.keys(bestBuyActions)
  }
}

export default new BestBuyActions()
