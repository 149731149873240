import React, {useEffect, useState} from 'react'
import {Button, Card, Col, Container, Form, Row} from 'react-bootstrap'
import {Formik} from 'formik'
import * as yup from 'yup'
import {addCurrencies} from '../../services/api'
import {useTranslation} from 'react-i18next'
import Select from 'react-select'
import {
  saveSaleWallet,
  loadListNetwork,
  loadListCurrencies,
} from '../../services/api'

export default function AddCurrency(props) {
  const {t} = useTranslation()
  const {onClose, addNewCurrency} = props
  const [validated, setValidated] = useState(false)
  let basket = {
    name: {fa: ''},
    symbol: '',
    network: '',
    sarrafeh_buy_fee: '',
    sarrafeh_sell_fee: '',
    icon: '',
    webp_icon: '',
    active_buy: '',
    active_sell: '',
    order: 1,
    need_tag: false,
  }
  const [listNetwork, setListNetwork] = useState({data: []})

  const activeCurrencyList = [
    {
      label: 'فعال',
      value: true,
    },
    {
      label: 'غیر فعال',
      value: false,
    },
  ]
  const neddTagList = [
    {
      label: 'دارد',
      value: true,
    },
    {
      label: 'ندارد',
      value: false,
    },
  ]

  useEffect(() => {
    loadListNetwork({
      page: 1,
      size: 100,
    })
      .then(response => {
        response.data.results.map(item => {
          let flagDta = {}
          flagDta.label = `${item.name.fa} - ${item.symbol}`
          flagDta.value = item.id

          listNetwork.data.push(flagDta)
          setListNetwork({...listNetwork})
        })
      })
      .catch(err => alert(JSON.stringify(err)))
  }, [])

  const schema = yup.object().shape({
    symbol: yup.string().required('این فیلد اجباری است'),
    network: yup.number().required(),
    active_buy: yup.string().required(),
    active_sell: yup.string().required(),
    order: yup.number().required(),
    need_tag: yup.string().required(),
    sarrafeh_buy_fee: yup.number().required(),
    sarrafeh_sell_fee: yup.number().required(),
  })

  const handleSubmit = values => {
    const formData = new FormData()
    formData.append('symbol', values.symbol)
    formData.append('name', JSON.stringify(values.name))
    formData.append('network', values.network)
    formData.append('active_buy', values.active_buy)
    formData.append('active_sell', values.active_sell)
    formData.append('order', values.order)
    formData.append('need_tag', values.need_tag)
    formData.append('sarrafeh_buy_fee', values.sarrafeh_buy_fee)
    formData.append('sarrafeh_sell_fee', values.sarrafeh_sell_fee)
    formData.append('icon', values.icon)
    formData.append('webp_icon', values.webp_icon)

    addCurrencies(formData)
      .then(response => {
        alert('عملیات با موفقیت انجام شد')
        addNewCurrency(prev => [
          ...prev,
          {
            ...response.data,
            icon: (
              <img
                src={response.data.icon}
                alt={response.data.symbol}
                width="30"
                height="30"
              />
            ),
            name: response.data.name.fa,
          },
        ])
        onClose()
      })
      .catch(err => alert(JSON.stringify(err)))
  }

  return (
    <>
      <Container fluid>
        <Row sm={12} md={12}>
          <Formik
            validated={validated}
            initialValues={basket}
            validationSchema={schema}
            onSubmit={values => handleSubmit(values)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Card className="stacked-form">
                  <Card.Header>
                    <Card.Title as="h4">{t('currency.add')}</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Form.Group>
                      <label>{t('currency.symbol')}</label>
                      <Form.Control
                        required
                        placeholder="symbol"
                        type="text"
                        name="symbol"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.symbol}
                      ></Form.Control>
                      <label>{t('currency.name')}</label>
                      <Form.Control
                        required
                        placeholder="name"
                        type="text"
                        name="name"
                        onChange={event =>
                          setFieldValue('name', {fa: event.target.value})
                        }
                        onBlur={handleBlur}
                        value={values.name.fa}
                      ></Form.Control>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div>
                          <label>فی فروش</label>

                          <Form.Control
                            required
                            placeholder="sarrafeh_sell_fee"
                            type="number"
                            step="0.0001"
                            name="sarrafeh_sell_fee"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.sarrafeh_sell_fee}
                          ></Form.Control>
                        </div>
                        <div>
                          <label>فی خرید</label>
                          <Form.Control
                            required
                            placeholder="sarrafeh_buy_fee"
                            type="number"
                            step="0.0001"
                            name="sarrafeh_buy_fee"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.sarrafeh_buy_fee}
                          ></Form.Control>
                        </div>
                      </div>

                      <label>{t('currency.network')}</label>
                      <Select
                        name="network"
                        options={listNetwork.data}
                        palceholder="network"
                        onChange={event =>
                          setFieldValue('network', event.value)
                        }
                        onBlur={setFieldTouched}
                      />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div
                          style={{
                            flexBasis: '49.5%',
                          }}
                        >
                          <label>{t('currency.active_buy')}</label>
                          <Select
                            name="active_buy"
                            options={activeCurrencyList}
                            palceholder="active_buy"
                            onChange={event => {
                              setFieldValue('active_buy', event.value)
                            }}
                            onBlur={setFieldTouched}
                          />
                        </div>

                        <div
                          style={{
                            flexBasis: '50%',
                          }}
                        >
                          {' '}
                          <label>{t('currency.active_sell')}</label>
                          <Select
                            name="active_sell"
                            options={activeCurrencyList}
                            palceholder="active_sell"
                            onChange={event => {
                              setFieldValue('active_sell', event.value)
                            }}
                            onBlur={setFieldTouched}
                          />
                        </div>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div
                          style={{
                            flexBasis: '50%',
                          }}
                        >
                          <label>{t('currency.order')}</label>

                          <Form.Control
                            required
                            placeholder={t('currency.order')}
                            type="number"
                            step="1"
                            name="order"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.order}
                          ></Form.Control>
                        </div>
                        <div
                          style={{
                            flexBasis: '49.5%',
                          }}
                        >
                          <label>{t('currency.need_tag')}</label>
                          <Select
                            name="need_tag"
                            options={neddTagList}
                            palceholder={t('currency.need_tag')}
                            onChange={event => {
                              setFieldValue('need_tag', event.value)
                            }}
                            onBlur={setFieldTouched}
                          />
                        </div>
                      </div>

                      <label>{t('currency.icon')}</label>
                      <Form.Control
                        required
                        type="file"
                        name="icon"
                        accept="image/png"
                        onChange={event =>
                          setFieldValue('icon', event.target.files[0])
                        }
                        onBlur={handleBlur}
                      ></Form.Control>

                      <label>{t('currency.iconWebp')}</label>
                      <Form.Control
                        required
                        type="file"
                        name="webp_icon"
                        accept="image/webp"
                        onChange={event =>
                          setFieldValue('webp_icon', event.target.files[0])
                        }
                        onBlur={handleBlur}
                      ></Form.Control>
                    </Form.Group>
                  </Card.Body>
                  <Card.Footer>
                    <Row>
                      <Col md={2} className="ml-3">
                        <Button
                          className="btn-fill"
                          type="submit"
                          variant="info"
                        >
                          {t('common.save')}
                        </Button>
                      </Col>
                      <Col md={2}>
                        <Button
                          className="btn-fill"
                          onClick={onClose}
                          variant="info"
                        >
                          {t('common.close')}
                        </Button>
                      </Col>
                    </Row>
                  </Card.Footer>
                </Card>
              </Form>
            )}
          </Formik>
        </Row>
      </Container>
    </>
  )
}
