const networks = Object.freeze({
  BTC_MAINNET: { value: "BM", label: "MainNet" },
  TRON_NET: { value: "TRC20", label: "TRC20" },
  RippleNet: { value: "RN", label: "RN" },
  ETHEREUM_ERC20: { value: "ERC20", label: "ERC20" },
});

class Network {
  getById(value) {
    let selectedStatus = Object.entries(networks).find(
      (net) => net[1].value === value
    );
    return networks[selectedStatus[0]];
  }

  getValues() {
    return Object.keys(networks);
  }
}

export default new Network();
