import React, {useEffect, useMemo, useState} from 'react'
import {Container, Row, Col, Card, Button} from 'react-bootstrap'
import ReactTable from '../../components/ReactTable/ReactTable'
import {FormGroup, Input} from 'reactstrap'
import Select from 'react-select'
import {loadWithdrawRequestList} from '../../services/api'
import WithdrawTransaction from './WithdrawTransaction'
import {useTranslation} from 'react-i18next'
import PurchaseStatus from '../../enums/PurchaseStatus'
import YesNo from '../../enums/YesNo'
import WithdrawDetail from './WithdrawDetail'
import AssignedWallet from './AssignedWallet'

export default function WithdrawRequestList(props) {
  const {t} = useTranslation()

  const [withdrawList, setWithdrawList] = useState([])
  const [showTransactionDialog, setShowTransactionDialog] = useState(false)
  const [showDetailDialog, setShowDetailDialog] = useState(false)
  const [showWalletDialog, setShowWalletDialog] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState()
  const [assignedWallet, setAssignedWallet] = useState()
  const [personProperty, setPersonProperty] = useState({})
  const [count, setCount] = useState(0)
  const [next, setNext] = useState('')
  const [user, setUser] = useState('')
  const [id, setId] = useState('')
  const [status, setStatus] = useState('')
  const [qty_now, setQty_now] = useState('')
  const [txid, setTxid] = useState('')
  const [loading, setLoading] = useState(false)
  const [sort_field, setSort_field] = useState('-last_update')

  const withdrawRequestColumns = [
    {
      Header: t('common.customer_name'),
      accessor: d => `${d.customer.name}`,
      flexGrow: 1,
      width: '100%',
      style: {overflowWrap: 'break-word'},
      Footer: () => {
        return (
          <>
            {t('table.countRows')} {count}
          </>
        )
      },
    },

    {
      Header: t('withdrawReq.table.amount'),
      accessor: d =>
        d.withdraw.amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
      minWidth: 300,
    },

    {
      id: 'closed',
      Header: t('withdrawReq.table.closed'),
      accessor: d => YesNo.getById(d.withdraw.closed).title,
    },

    {
      Header: 'txid',
      accessor: d => d.withdraw.txid,
    },
    {
      Header: t('common.status'),
      accessor: d => PurchaseStatus.getById(d.withdraw.status).title,
    },
    {
      Header: t('common.actions'),
      accessor: 'actions',
      sortable: false,
      filterable: false,
    },
  ]

  const statusOption = [
    {label: 'در صف', value: 'Q'},
    {label: 'خطا', value: 'F'},
    {label: 'موفق', value: 'S'},
  ]

  const fetchData = (page, size) => {
    if (page > 0 && size > 0) {
      const data = {
        page: page,
        size: size,
        id: id,
        user: user,
        status: status,
        sort_field: sort_field,
        qty_now: qty_now,
        txid: txid,
      }
      setLoading(true)
      loadWithdrawRequestList(data)
        .then(response => {
          setWithdrawList(
            response.data.results
              .map((prop, key) => {
                return {
                  ...prop,
                  actions: (
                    <div className="actions-right">
                      <Button
                        onClick={() => {
                          setAssignedWallet('')
                          let wallet = prop.withdraw.wallet
                          setAssignedWallet(wallet)
                          wallet.buyId = prop.withdraw.id
                          setPersonProperty({
                            qty_now: prop.withdraw.amount,
                            tracker_code: prop.withdraw.txid,
                          })

                          setShowWalletDialog(true)
                        }}
                        variant="primary"
                        size="sm"
                        className="btn-link like"
                      >
                        <i className="fa fa-wallet" />
                      </Button>
                      <Button
                        onClick={() => {
                          setSelectedOrder(prop)
                          setShowDetailDialog(true)
                        }}
                        variant="info"
                        size="sm"
                        className="text-info btn-link like"
                      >
                        <i className="fa fa-eye" />
                      </Button>
                      <Button
                        onClick={() => {
                          setSelectedOrder(prop)
                          setShowTransactionDialog(true)
                        }}
                        variant="warning"
                        size="sm"
                        className="text-warning btn-link edit"
                      >
                        <i className="fa fa-edit" />
                      </Button>
                    </div>
                  ),
                }
              })
              .sort((a, b) => (a.withdraw.id > b.withdraw.id ? -1 : 1)),
          )
          setCount(response.data.count)
          setNext(response.data.next)
          setLoading(false)
        })
        .catch(err => {
          alert('عملیات با خطا مواجه شد')
          alert(JSON.stringify(err))
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    setAssignedWallet('')
    let timeout = null
    if (!timeout) {
      timeout = setTimeout(() => {
        fetchData(1, 10)
      }, 500)
    }

    return () => clearTimeout(timeout)
  }, [user, status, sort_field, id, qty_now, txid])

  return (
    <>
      <Container fluid>
        {selectedOrder && (
          <WithdrawDetail
            purchase={selectedOrder.withdraw}
            show={showDetailDialog}
            onHide={() => setShowDetailDialog(false)}
          />
        )}
        {selectedOrder && (
          <WithdrawTransaction
            purchaseId={selectedOrder.withdraw.id}
            show={showTransactionDialog}
            purchases={withdrawList}
            setPurchaseOrders={setWithdrawList}
            onHide={() => setShowTransactionDialog(false)}
          />
        )}

        {assignedWallet && (
          <AssignedWallet
            wallet={assignedWallet}
            personProperty={personProperty}
            show={showWalletDialog}
            onHide={() => setShowWalletDialog(false)}
          />
        )}
        <Row>
          <Col md="12">
            <h5>{t('withdrawReq.title')}</h5>
            <Card>
              <div className="filter-area">
                <Col md="4">
                  <FormGroup>
                    <label>نام مشتری</label>
                    <Input
                      name="name"
                      placeholder="name"
                      type="text"
                      onChange={e => {
                        setUser(e.target.value || '')
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label>وضعیت</label>
                    <Select
                      name="status"
                      options={statusOption}
                      palceholder="status"
                      onChange={event => {
                        setStatus(event.value)
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col md="4">
                  <FormGroup>
                    <label>txid</label>
                    <Input
                      name="txid"
                      placeholder="txid"
                      type="text"
                      onChange={e => {
                        setTxid(e.target.value || '')
                      }}
                    />
                  </FormGroup>
                </Col>
              </div>

              <Card.Body>
                <ReactTable
                  data={withdrawList}
                  count={count}
                  next={next}
                  columns={withdrawRequestColumns}
                  className="-striped -highlight primary-pagination"
                  fetchingData={(pageIndex, pageSize) =>
                    fetchData(pageIndex, pageSize)
                  }
                  loading={loading}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}
