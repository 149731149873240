const actives = Object.freeze({
    ACTIVE: {id: true, title: "فعال"},
    NOT_ACTIVE: {id: false, title: "غیر فعال"}
});

class Active
{
    getById(id){
        let selectedStatus =Object.entries(actives).find(value => value[1].id === id);
        return actives[selectedStatus[0]];
    }

    getValues(){
        return Object.keys(actives);
    }

}

export default new Active();
