import React, {useEffect, useState} from 'react'
import {Button, Card, Col, Container, Form, Row} from 'react-bootstrap'
import {Formik} from 'formik'
import {useTranslation} from 'react-i18next'
import Select from 'react-select'
import {
  loadListPortfolioCurrency,
  editPortfolioCurrency,
} from '../../../services/api'

export default function EditPortfolioCurrency(props) {
  const {t} = useTranslation()
  const {onClose, selectedCurrency, editStateCurrencies} = props

  let portfolio = {
    name: {fa: selectedCurrency.name.fa},
    symbol: selectedCurrency.symbol,
    binance_pair_symbol: selectedCurrency.binance_pair_symbol,
    max_digits: selectedCurrency.max_digits,
    decimal_point: selectedCurrency.decimal_point,
    logo: selectedCurrency.logo,
    id: selectedCurrency?.id,
  }

  const [listPortfolio, setListPortfolio] = useState({data: []})
  const [logo, setLogo] = useState(false)

  const getLabelByValue = (Array, values) => {
    let object = Array.find(obj => obj.value === values)
    return object.label
  }

  useEffect(() => {
    loadListPortfolioCurrency({
      page: 1,
      size: 500,
    })
      .then(response => {
        response.data.results.map(item => {
          let flagDta = {}
          flagDta.label = `${item.name.fa} - ${item.symbol}`
          flagDta.value = item.id

          listPortfolio.data.push(flagDta)
          setListPortfolio({...listPortfolio})
        })
      })
      .catch(err => alert(JSON.stringify(err)))
  }, [])

  return (
    <>
      <Container fluid>
        <Row sm={1} md={2}>
          <Formik
            initialValues={portfolio}
            onSubmit={values => {
              const formData = new FormData()

              formData.append('symbol', values.symbol)
              formData.append('name', JSON.stringify(values.name))
              formData.append('binance_pair_symbol', values.binance_pair_symbol)
              formData.append('max_digits', values.max_digits)
              formData.append('decimal_point', values.decimal_point)
              logo && formData.append('logo', values.logo)
              const id = values.id
              editPortfolioCurrency(id, formData)
                .then(response => {
                  alert('عملیات با موفقیت انجام شد')
                  editStateCurrencies(prev => {
                    let array = prev
                    const editedData = array.find(
                      item => item.id === response.data.id,
                    )
                    array = array.filter(item => item !== editedData)

                    const newArray = [
                      ...array,
                      {
                        ...response.data,
                        name: response.data.name.fa,
                        actions: editedData.actions,
                      },
                    ]

                    return newArray
                  })
                })
                .catch(err => {
                  alert(JSON.stringify(err))
                })
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Card className="stacked-form">
                  <Card.Header>
                    <Card.Title as="h4">{t('currency.edit')}</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Form.Group>
                      <label>{t('currency.symbol')}</label>
                      <Form.Control
                        required
                        placeholder="symbol"
                        type="text"
                        name="symbol"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.symbol}
                      ></Form.Control>
                      <label>{t('currency.name')}</label>
                      <Form.Control
                        required
                        placeholder="name"
                        type="text"
                        name="name"
                        onChange={event =>
                          setFieldValue('name', {fa: event.target.value})
                        }
                        onBlur={handleBlur}
                        value={values.name.fa}
                      ></Form.Control>

                      <label>binance_pair_symbol</label>
                      <Form.Control
                        required
                        placeholder="binance_pair_symbol"
                        type="text"
                        name="binance_pair_symbol"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.binance_pair_symbol}
                      ></Form.Control>

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div>
                          <label>max_digits</label>

                          <Form.Control
                            required
                            placeholder="max_digits"
                            type="number"
                            step="1"
                            name="max_digits"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.max_digits}
                          ></Form.Control>
                        </div>
                        <div>
                          <label>decimal_point</label>
                          <Form.Control
                            required
                            placeholder="decimal_point"
                            type="number"
                            step="1"
                            name="decimal_point"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.decimal_point}
                          ></Form.Control>
                        </div>
                      </div>

                      <label>{t('currency.logo')}</label>
                      <Form.Control
                        type="file"
                        name="logo"
                        accept="image/svg"
                        onChange={event => {
                          setFieldValue('logo', event.target.files[0])
                          if (event.target.files[0]) {
                            setLogo(true)
                          } else {
                            setLogo(false)
                          }
                        }}
                        onBlur={handleBlur}
                      ></Form.Control>
                    </Form.Group>
                  </Card.Body>
                  <Card.Footer>
                    <Row>
                      <Col md={2} className="ml-3">
                        <Button
                          className="btn-fill"
                          type="submit"
                          variant="info"
                        >
                          {t('currency.edit')}
                        </Button>
                      </Col>
                      <Col md={2}>
                        <Button
                          className="btn-fill"
                          onClick={onClose}
                          variant="info"
                        >
                          {t('common.close')}
                        </Button>
                      </Col>
                    </Row>
                  </Card.Footer>
                </Card>
              </Form>
            )}
          </Formik>
        </Row>
      </Container>
    </>
  )
}
