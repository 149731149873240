import React from 'react'

// react-bootstrap components
import {Badge, Button, Card, Navbar, Nav, Container, Col} from 'react-bootstrap'

function AuthFooter() {
  return (
    <>
      <footer className="footer position-absolute fixed-bottom">
        <Container>
          <nav>
            <ul className="footer-menu d-flex justify-content-center">
              <li>
                <a className="m-0" href="https://sarrafeh.com/">
                  صرافه
                </a>
              </li>
              <li>
                <a className="m-0" href="https://blog.sarrafeh.com/">
                  وبلاگ
                </a>
              </li>
            </ul>
            <p className="copyright text-center m-0">
              کلیه حقوق وب سایت متعلق به شرکت
              <a href="http://www.coin-plus.org"> کوین پلاس</a>©{' '}
              {new Date().getFullYear()} می باشد.
            </p>
          </nav>
        </Container>
      </footer>
    </>
  )
}

export default AuthFooter
