import React, {useEffect, useState, useMemo} from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import {FormGroup, Input} from 'reactstrap'
import ReactTable from '../../components/ReactTable/ReactTable'
import Button from 'react-bootstrap/Button'
import Select from 'react-select'
import {loadCustomerProfiles} from '../../services/api'
import VerifyCustomerProfile from './VerifyCustomerProfile'
import {useTranslation} from 'react-i18next'
import ProfileStatus from '../../enums/ProfileStatus'
import CustomerProfileDetail from './CustomerProfileDetail'

export default function CustomerProfiles(props) {
  const {t} = useTranslation()

  const statusOption = [
    {label: 'تکمیل', value: 1},
    {label: 'ناقص', value: 0},
    {label: 'همه', value: ''},
  ]

  const initialStates = useMemo(
    () => ({
      profiles: [],
      showVerifyDialog: false,
      showDetailDialog: false,
      selectedCustomer: false,
      loading: false,
      page: 0,
      size: 0,
      count: 0,
      next: '',
      id: '',
      melli_code: '',
      name: '',
      sort_field: '-id',
      profileStatus: '',
      startDate: '',
      endDate: '',
    }),
    [],
  )

  const [state, setState] = useState(initialStates)

  const {
    profiles,
    showVerifyDialog,
    showDetailDialog,
    selectedCustomer,
    loading,
    page,
    size,
    count,
    next,
    id,
    melli_code,
    name,
    sort_field,
    profileStatus,
    startDate,
    endDate,
  } = state

  const profileColumns = [
    {
      Header: t('common.id'),
      accessor: 'id',
      Footer: () => {
        return (
          <>
            {t('table.countRows')} {count}
          </>
        )
      },
    },
    {
      Header: t('customerProfiles.table.fullName'),
      accessor: 'name',
      size: 4,
    },
    {
      Header: t('customerProfiles.table.username'),
      accessor: 'username',
    },
    {
      Header: t('customerProfiles.table.melliCode'),
      accessor: 'melli_code',
    },

    {
      Header: t('customerProfiles.table.city'),
      accessor: 'city',
    },
    {
      Header: t('customerProfiles.table.email'),
      accessor: 'email',
    },
    {
      Header: t('customerProfiles.table.mobile'),
      accessor: 'mobile',
    },
    {
      Header: t('customerProfiles.table.profile-status'),
      accessor: d => ProfileStatus.getById(d.profile).title,
    },
    {
      Header: t('common.actions'),
      accessor: 'actions',
      sortable: false,
      filterable: false,
    },
  ]

  const setProfiles = value => {
    setState(prev => ({
      ...prev,
      profiles: value,
    }))
  }
  const setShowVerifyDialog = value => {
    setState(prev => ({
      ...prev,
      showVerifyDialog: value,
    }))
  }
  const setShowDetailDialog = value => {
    setState(prev => ({
      ...prev,
      showDetailDialog: value,
    }))
  }
  const setSelectedCustomer = value => {
    setState(prev => ({
      ...prev,
      selectedCustomer: value,
    }))
  }

  const fetchData = (page, size) => {
    if (page > 0 && size > 0) {
      const data = {
        page: page,
        size: size,
        id: id,
        melli_code: melli_code,
        name: name,
        sort_field: sort_field,
        profile: profileStatus,
      }
      setState(prev => ({
        ...prev,
        loading: true,
      }))
      loadCustomerProfiles(data)
        .then(response => {
          setProfiles(
            response.data.results.map((prop, key) => {
              return {
                ...prop,
                actions: (
                  <div className="actions-right">
                    <Button
                      onClick={() => {
                        setSelectedCustomer(prop)
                        setShowDetailDialog(true)
                      }}
                      variant="info"
                      size="sm"
                      className="text-info btn-link like"
                    >
                      <i className="fa fa-eye" />
                    </Button>
                  </div>
                ),
              }
            }),
          )
          setState(prev => ({
            ...prev,
            count: response.data.count,
            next: response.data.next,
            loading: false,
          }))
        })
        .catch(err => {
          alert('عملیات با خطا مواجه شد')
          alert(JSON.stringify(err))
          setState(prev => ({
            ...prev,
            loading: false,
          }))
        })
    }
  }

  useEffect(() => {
    let timeout = null
    if (!timeout) {
      timeout = setTimeout(() => {
        fetchData(1, 10)
      }, 800)
    }

    return () => clearTimeout(timeout)
  }, [id, melli_code, name, sort_field, profileStatus])

  return (
    <>
      <Container fluid>
        {selectedCustomer && (
          <VerifyCustomerProfile
            show={showVerifyDialog}
            setprofiles={setProfiles}
            profiles={profiles}
            onHide={() => setShowVerifyDialog(false)}
            selectedCustomer={selectedCustomer}
          />
        )}
        {selectedCustomer && (
          <CustomerProfileDetail
            profile={selectedCustomer}
            show={showDetailDialog}
            onHide={() => setShowDetailDialog(false)}
          />
        )}
        <Row>
          <Col md="12">
            <Card>
              <div className="filter-area">
                <Col md="4">
                  <FormGroup>
                    <label>نام</label>
                    <Input
                      name="name"
                      placeholder="name"
                      type="text"
                      onChange={e => {
                        setState(prev => ({
                          ...prev,
                          name: e.target.value || '',
                        }))
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label>کد ملی</label>
                    <Input
                      name="melli_code"
                      placeholder="melli_code"
                      type="text"
                      onChange={e => {
                        setState(prev => ({
                          ...prev,
                          melli_code: e.target.value || '',
                        }))
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label>شناسه</label>
                    <Input
                      name="id"
                      placeholder="id"
                      type="text"
                      onChange={e => {
                        setState(prev => ({
                          ...prev,
                          id: e.target.value || '',
                        }))
                      }}
                    />
                  </FormGroup>
                </Col>

                {/* <Col md="4">
                  <FormGroup>
                    <label>وضعیت</label>
                    <Select
                      name="status"
                      options={statusOption}
                      palceholder="status"
                      onChange={event => {
                        setState(prev => ({
                          ...prev,
                          profileStatus: event.value,
                        }))
                      }}
                    />
                  </FormGroup>
                </Col> */}

                {/* <Col md="4">
                  <FormGroup>
                    <label>از تاریخ </label>
                    <Input
                      name="startDate"
                      placeholder="2022-01-01"
                      type="text"
                      onChange={e => {
                        setState(prev => ({
                          ...prev,
                          startDate: e.target.value || '',
                        }))
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col md="4">
                  <FormGroup>
                    <label>تا تاریخ </label>
                    <Input
                      name="endDate"
                      placeholder="2022-01-01"
                      type="text"
                      onChange={e => {
                        setState(prev => ({
                          ...prev,
                          endDate: e.target.value || '',
                        }))
                      }}
                    />
                  </FormGroup>
                </Col> */}
              </div>

              <Card.Body>
                <ReactTable
                  data={profiles}
                  count={count}
                  next={next}
                  columns={profileColumns}
                  className="-striped -highlight primary-pagination"
                  fetchingData={(pageIndex, pageSize) =>
                    fetchData(pageIndex, pageSize)
                  }
                  loading={loading}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}
