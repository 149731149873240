import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { Container, Form, Row, Col, Card, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { generateVandarToken } from "../../services/api";

export default function VandarToken(props) {
  const { t } = useTranslation();
  const { onHide } = props;
  const [mobileNo, setMobileNo] = useState();
  const [password, setPassword] = useState();

  const generateToken = () => {
    let requestDto = {
      vandar_mobile: mobileNo,
      vandar_password: password,
    };
    generateVandarToken(requestDto)
      .then((response) => {
        alert("Confirmed successfully");
        onHide();
      })
      .catch((err) => alert(JSON.stringify(err.response.data.detail)));
  };

  return (
    <Modal
      size="lg"
      centered
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("configParam.create_vandar_login_token")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Card>
          <Card.Body>
            <Card.Text>
              <Container>
                <Row>
                  <Col md={6}>
                    <Form.Group>
                      <label>{t("configParam.vandar_mobile")}</label>
                      <Form.Control
                        placeholder="vandar_mobile"
                        type="text"
                        name="vandar_mobile"
                        onChange={(e) => setMobileNo(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <label>{t("configParam.vandar_password")}</label>
                      <Form.Control
                        placeholder="vandar_password"
                        type="password"
                        name="vandar_password"
                        onChange={(e) => setPassword(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              </Container>
            </Card.Text>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>{t("common.close")}</Button>
        <Button onClick={generateToken}>{t("common.confirm")}</Button>
      </Modal.Footer>
    </Modal>
  );
}
