import React from 'react'
import Button from 'react-bootstrap/Button'
import {Container, Form} from 'react-bootstrap'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
import {useTranslation} from 'react-i18next'
import YesNo from '../../enums/YesNo'
import DateUtil from '../../utils/DateUtil'

export default function BasketDetail(props) {
  const {t} = useTranslation()
  const {basket, onHide} = props

  return (
    <Modal
      size="lg"
      centered
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('bestBuy.detail.title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Card>
          <Card.Body>
            <Card.Text>
              <Col xs={4} md={4}>
                <div>
                  <h6>{t('common.id')}</h6>
                  <p className="category">{basket.id}</p>
                </div>
              </Col>

              <Col xs={4} md={4}>
                <div>
                  <h6>{t('bestBuy.basket-price-usdt')}</h6>
                  <p className="category">{basket.basket_price.usdt}</p>
                </div>
              </Col>

              <Col xs={4} md={4}>
                <div>
                  <h6>{t('common.user')}</h6>
                  <p className="category">{basket.customer_data.name}</p>
                </div>
              </Col>

              <Col xs={4} md={4}>
                <div>
                  <h6>{t('customerProfiles.national-code')}</h6>
                  <p className="category">{basket.customer_data.melli_code}</p>
                </div>
              </Col>

              <Col xs={4} md={4}>
                <div>
                  <h6>{t('customerProfiles.phone_num')}</h6>
                  <p className="category">{basket.customer_data.mobile}</p>
                </div>
              </Col>

              <Col xs={4} md={4}>
                <div>
                  <h6>{t('common.create_datetime')}</h6>
                  <p className="category">
                    {DateUtil.convertToJalaliBirthDate(basket.created_on)}
                  </p>
                </div>
              </Col>

              <Col xs={4} md={4}>
                <div>
                  <h6>{t('bestBuy.last_update')}</h6>
                  <p className="category">
                    {DateUtil.convertToJalaliBirthDate(basket.last_update)}
                  </p>
                </div>
              </Col>

              <Col xs={4} md={4}>
                <div>
                  <h6>{t('bestBuy.detail.entered_tether')}</h6>
                  <p className="category">{basket.entered_usdt}</p>
                </div>
              </Col>

              <Col xs={12} md={12}>
                <div>
                  <h6>{t('bestBuy.detail.detail')} </h6>
                  <p className="category">{JSON.stringify(basket.detail)}</p>
                </div>
              </Col>
            </Card.Text>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>{t('common.close')}</Button>
      </Modal.Footer>
    </Modal>
  )
}
