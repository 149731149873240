const yesNos = Object.freeze({
    EMPTY: {id: "",title:""},
    YES: {id: true, title: "بله"},
    NO: {id: false, title: "خیر"}
});

class YesNo
{
    getById(id){
        let selectedStatus =Object.entries(yesNos).find(value => value[1].id === id);
        if (!selectedStatus) return yesNos.EMPTY;
        return yesNos[selectedStatus[0]];
    }

    getValues(){
        return Object.keys(yesNos);
    }

}

export default new YesNo();
