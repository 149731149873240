import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Form } from "react-bootstrap";
import update from "immutability-helper";
import { useTranslation } from "react-i18next";
import CustomerProfileStatus from "../../enums/CustomerProfileStatus";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Button from "react-bootstrap/Button";

export default function Debit(props) {
  const { debit, setDebitVerifications, debitVerifications, key } = props;
  const [isCopied, setIsCopied] = useState(false);
  const [t] = useTranslation();

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const handleVerify = (event) => {
    let index = debitVerifications.findIndex((item) => item.id === debit.id);
    let verifyDto = { id: debit.id, verified: event.target.value };
    let dataRows = update(debitVerifications, {
      $splice: [[index, 1, verifyDto]],
    });
    setDebitVerifications(dataRows);
  };

  return (
    <Card key={key}>
      <Card.Body>
        <Card.Title>{t("customerProfiles.debit.title")}</Card.Title>
        <Card.Text>
          <Container>
            <Row>
              <Col xs={6} md={6}>
                <p>
                  {t("customerProfiles.debit.name")}: {debit.name}
                </p>
              </Col>
              <Col xs={6} md={6}>
                <p>
                  {t("customerProfiles.debit.verifiedStatus")}:{" "}
                  {CustomerProfileStatus.getById(debit.verified).title}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <div style={{ display: "flex" }}>
                  <p>
                    {t("customerProfiles.debit.cardNumber")}:{" "}
                    {debit.card_number}
                  </p>
                  <CopyToClipboard text={debit.card_number} onCopy={onCopyText}>
                    <div>
                      <Button
                        data-toggle="tooltip"
                        data-placement="top"
                        variant="info"
                        size="sm"
                        className="text-info btn-link like"
                      >
                        <i className="fa fa-copy" />
                      </Button>
                      {isCopied && <span>کپی شد</span>}
                    </div>
                  </CopyToClipboard>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <div style={{ display: "flex" }}>
                  <p>
                    {t("customerProfiles.debit.shebaNumber")}:{" "}
                    {debit.sheba_number}
                  </p>
                  <CopyToClipboard
                    text={debit.sheba_number}
                    onCopy={onCopyText}
                  >
                    <div>
                      <Button
                        data-toggle="tooltip"
                        data-placement="top"
                        variant="info"
                        size="sm"
                        className="text-info btn-link like"
                      >
                        <i className="fa fa-copy" />
                      </Button>
                      {isCopied && <span>کپی شد</span>}
                    </div>
                  </CopyToClipboard>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <fieldset>
                  <Form.Group>
                    <Row>
                      <Col xs={12} md={10}>
                        <Form.Check className="form-check-radio">
                          <Form.Check.Label>
                            <Form.Check.Input
                              defaultChecked={debit.verified === "V"}
                              defaultValue="V"
                              id="verified"
                              name={`verified${debit.id}`}
                              type="radio"
                              onChange={handleVerify}
                            ></Form.Check.Input>
                            <span className="form-check-sign"></span>
                            {t("customerProfiles.debit.verified")}
                          </Form.Check.Label>
                        </Form.Check>
                        <Form.Check className="form-check-radio">
                          <Form.Check.Label>
                            <Form.Check.Input
                              defaultChecked={debit.verified === "R"}
                              defaultValue="R"
                              id="notVerified"
                              name={`verified${debit.id}`}
                              type="radio"
                              onChange={handleVerify}
                            ></Form.Check.Input>
                            <span className="form-check-sign"></span>
                            {t("customerProfiles.debit.notVerified")}
                          </Form.Check.Label>
                        </Form.Check>
                      </Col>
                    </Row>
                  </Form.Group>
                </fieldset>
              </Col>
            </Row>
          </Container>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
