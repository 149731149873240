const profileStatusList = Object.freeze({
  VERIFIED: {id: 1, title: 'تکمیل'},
  NOT_VERIFIED: {id: 0, title: 'ناقص'},
})

class ProfileStatus {
  getById(id) {
    let selectedStatus = Object.entries(profileStatusList).find(
      value => value[1].id === id,
    )
    return profileStatusList[selectedStatus[0]]
  }

  getValues() {
    return Object.keys(profileStatusList)
  }
}

export default new ProfileStatus()
