import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./AuthContextProvider";

export default function PrivateRoute(props) {

    const { component, role, ...rest } = props;
    const { authenticated, authPrincipal } = useAuth();

    let authorized = true;
    if (
        role &&
        authPrincipal.user.roles.findIndex(value => value.code === role) == -1
    )
        authorized = false;

    return authenticated && authorized ? (
        <Route component={component} {...rest} />
    ) : !authenticated ? (
        <Redirect from={props.location.pathname} to="/auth/login-page" />
    ) : (
        !authorized && <Redirect from={props.location.pathname} to="/error" />
    );
}
