const customerProfileStatuses = Object.freeze({
    EMPTY: {id: "",title:""},
    QUEUE: {id: "Q", title: "در صف"},
    VERIFIED: {id: "V", title: "تایید شده"},
    REJECTED: {id: "R", title: "رد شده"}
});

class CustomerProfileStatus
{
     getById(id){
         let selectedStatus =Object.entries(customerProfileStatuses).find(value => value[1].id === id);
         if (!selectedStatus) return customerProfileStatuses.EMPTY;
        return customerProfileStatuses[selectedStatus[0]];
}

 getValues(){
        return Object.keys(customerProfileStatuses);
}

}

export default new CustomerProfileStatus();
