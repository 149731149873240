import Dashboard from 'views/Dashboard.js'
import CustomerProfiles from './views/CustomerProfiles/CustomerProfiles'
import PurchaseOrders from './views/Purchases/PurchaseOrders'
import SaleOrders from './views/Sales/SaleOrders'
import ChargeRequestList from 'views/ChargeRequest/ChargeRequestList'
import RemainingOrders from './views/Order/RemainingOrders'
import BestBuyBaskets from './views/BestBuy/BestBuyBaskets'
import SystemParameters from './views/BaseInfo/SystemParameters'
import ListCurrencies from './views/Currency/ListCurrencies'
import ListNetwork from './views/Currency/ListNetwork'
import SaleWallets from './views/Wallets/SaleWallets'
import WithdrawOrders from './views/Withdraws/WithdrawOrders'
import BestBuyBasketsList from './views/‌BestBuyBaskets/BestBuyBasketsList'
import WithdrawRequestList from './views/WithdrawRequest/WithdrawRequestList'
import portfolioCurrency from './views/Currency/PortfolioCurrency/PortfolioCurrency'
import Reports from './views/Reports/Reports'
import ReportPerUser from './views/Reports/ReportPerUser'

const routes = [
  {
    path: '/dashboard',
    layout: '/admin',
    name: 'داشبورد',
    icon: 'nc-icon nc-chart-pie-35',
    component: Dashboard,
  },
  {
    path: '/customerProfiles',
    layout: '/admin',
    name: 'پروفایل های مشتری',
    icon: 'nc-icon nc-single-02',
    component: CustomerProfiles,
  },
  {
    collapse: true,
    path: '/Orders',
    layout: '/admin',
    name: 'سفارشات ',
    state: 'openOrders',
    icon: 'nc-icon nc-layers-3',
    views: [
      {
        path: '/purchaseOrders',
        layout: '/admin',
        name: 'سفارشات خرید',
        icon: 'nc-icon nc-cart-simple',
        component: PurchaseOrders,
      },
      {
        path: '/saleOrders',
        layout: '/admin',
        name: 'سفارشات فروش',
        icon: 'nc-icon nc-bag',
        component: SaleOrders,
      },
      {
        path: '/remaining',
        layout: '/admin',
        name: 'سفارشات خرد مانده',
        icon: 'nc-icon nc-cart-simple',
        component: RemainingOrders,
      },
    ],
  },
  {
    path: '/saleWallets',
    layout: '/admin',
    name: 'کیف پول های فروش',
    icon: 'nc-icon nc-credit-card',
    component: SaleWallets,
  },

  {
    collapse: true,
    path: '/chargeOrWithdraw',
    name: 'واریز و برداشت',
    state: 'openChargeOrWithdraw',
    icon: 'nc-icon nc-layers-3',
    views: [
      {
        path: '/charge',
        layout: '/admin',
        name: 'گزارشات واریز',
        icon: 'nc-icon nc-bank',
        component: ChargeRequestList,
      },
      {
        path: '/withdraw',
        layout: '/admin',
        name: 'درخواست های برداشت',
        icon: 'nc-icon nc-bank',
        component: WithdrawRequestList,
      },
    ],
  },
  {
    collapse: true,
    path: '/currencyAndNetwork',
    name: 'ارز و شبکه',
    state: 'openCurrency',
    icon: 'nc-icon nc-layers-3',
    views: [
      {
        path: '/currency',
        layout: '/admin',
        name: 'ارز',
        icon: 'nc-icon nc-app',
        component: ListCurrencies,
      },
      {
        path: '/portfolioCurrency',
        layout: '/admin',
        name: 'ارز های سبد هوشمند',
        icon: 'nc-icon nc-cart-simple',
        component: portfolioCurrency,
      },
      {
        path: '/network',
        layout: '/admin',
        name: 'شبکه',
        icon: 'nc-icon nc-grid-45',
        component: ListNetwork,
      },
    ],
  },

  {
    path: '/bestBuyBaskets',
    layout: '/admin',
    name: 'سبد های خرید بهینه',
    icon: 'nc-icon nc-cart-simple',
    component: BestBuyBasketsList,
  },
  {
    path: '/bestBuyAnalysis',
    layout: '/admin',
    name: 'تحلیل های خرید بهینه',
    icon: 'nc-icon nc-single-copy-04',
    component: BestBuyBaskets,
  },
  {
    collapse: true,
    path: '/reports',
    name: 'گزارشات',
    state: 'openReports',
    icon: 'nc-icon nc-layers-3',
    views: [
      {
        path: '/income',
        layout: '/admin',
        name: 'درآمد تمام تراکنش ها ',
        icon: 'nc-icon nc-single-copy-04',
        component: Reports,
      },
      {
        path: '/income-per-user',
        layout: '/admin',
        name: ' مجموع درآمد از هر کاربر',
        icon: 'nc-icon nc-single-copy-04',
        component: ReportPerUser,
      },
    ],
  },
  {
    path: '/systemParameters',
    layout: '/admin',
    name: 'تنظیمات سیستم',
    icon: 'nc-icon nc-settings-gear-64',
    component: SystemParameters,
  },
]
export default routes
