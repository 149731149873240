import React from 'react'
import {Switch, Route} from 'react-router-dom'
// react-bootstrap components
import {Badge, Button, Card, Navbar, Nav, Container, Col} from 'react-bootstrap'

import AuthNavbar from 'components/Navbars/AuthNavbar.js'
import AuthFooter from 'components/Footers/AuthFooter.js'

import LoginPage from '../views/Pages/LoginPage'

function Auth(props) {
  return (
    <>
      <div className="wrapper wrapper-full-page">
        {/* <AuthNavbar /> */}
        <LoginPage {...props} />
        <AuthFooter />
      </div>
    </>
  )
}

export default Auth
