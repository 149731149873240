import React from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import CustomerProfileStatus from "../../enums/CustomerProfileStatus";

export default function VerifyNationalCard(props){

    const {nationalCard,setVerification} = props;
    const {t} = useTranslation();
    const handleVerifyNationalCard =(event) =>{
        let verified = event.target.value;
        setVerification(prev => ({...prev,isNationalCardPhotoVerified:verified}));
    }
    const handleVerifyNationalCode =(event) =>{
        let verified = event.target.value;
        setVerification(prev => ({...prev,isNationalCodeVerified:verified}));
    }

    return (
        <Card >
        <Card.Body>
            <Card.Title>{t('customerProfiles.nationalCard.info')}</Card.Title>
            <Card.Text>
                <Container>
                    <Row>
                        <Col xs={4} md={4}>
                            <p>{t('customerProfiles.nationalCard.nationalCode')}: {nationalCard.melli_code}</p>
                        </Col>
                        <Col xs={4} md={4}>
                            <p>{t('customerProfiles.nationalCard.nationalCodeVerification')}: {CustomerProfileStatus.getById(nationalCard.melli_code_verification).title}</p>
                        </Col>
                        <Col xs={4} md={4}>
                            <p>{t('customerProfiles.nationalCard.verification')}: {CustomerProfileStatus.getById(nationalCard.melli_cart_verification).title}</p>
                        </Col>
                    </Row>
                </Container>
            </Card.Text>
            <Card.Img variant="top" src={nationalCard.melli_cart} />
            <Row>
                <Col xs={12} md={6}>
                <fieldset>
                    <Form.Group>
                        <Row>
                            <Col xs={12} md={10}>
                                <Form.Check className="form-check-radio">
                                    <Form.Check.Label>
                                        <Form.Check.Input
                                            defaultChecked={nationalCard.melli_cart_verification === "V"}
                                            defaultValue="V"
                                            id="verifiedNational"
                                            name="verifiedNational"
                                            type="radio"
                                            onChange={handleVerifyNationalCard}
                                        ></Form.Check.Input>
                                        <span className="form-check-sign"></span>
                                        {t('customerProfiles.nationalCard.verified')}
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className="form-check-radio">
                                    <Form.Check.Label>
                                        <Form.Check.Input
                                            defaultChecked={nationalCard.melli_cart_verification === "R"}
                                            defaultValue="R"
                                            id="notVerifiedNational"
                                            name="verifiedNational"
                                            type="radio"
                                            onChange={handleVerifyNationalCard}
                                        ></Form.Check.Input>
                                        <span className="form-check-sign"></span>
                                        {t('customerProfiles.nationalCard.notVerified')}
                                    </Form.Check.Label>
                                </Form.Check>
                            </Col>
                        </Row>
                    </Form.Group>
                </fieldset>
            </Col>
                <Col xs={12} md={6}>
                    <fieldset>
                        <Form.Group>
                            <Row>
                                <Col xs={12} md={10}>
                                    <Form.Check className="form-check-radio">
                                        <Form.Check.Label>
                                            <Form.Check.Input
                                                defaultChecked={nationalCard.melli_code_verification === "V"}
                                                defaultValue="V"
                                                id="verifiedNationalCode"
                                                name="verifiedNationalCode"
                                                type="radio"
                                                onChange={handleVerifyNationalCode}
                                            ></Form.Check.Input>
                                            <span className="form-check-sign"></span>
                                            {t('customerProfiles.nationalCode.verified')}
                                        </Form.Check.Label>
                                    </Form.Check>
                                    <Form.Check className="form-check-radio">
                                        <Form.Check.Label>
                                            <Form.Check.Input
                                                defaultChecked={nationalCard.melli_code_verification === "R"}
                                                defaultValue="R"
                                                id="notVerifiedNationalCode"
                                                name="verifiedNationalCode"
                                                type="radio"
                                                onChange={handleVerifyNationalCode}
                                            ></Form.Check.Input>
                                            <span className="form-check-sign"></span>
                                            {t('customerProfiles.nationalCode.notVerified')}
                                        </Form.Check.Label>
                                    </Form.Check>
                                </Col>
                            </Row>
                        </Form.Group>
                    </fieldset>
                </Col>
            </Row>
        </Card.Body>
    </Card>);

}
