import React, {useState} from 'react'

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Col,
} from 'react-bootstrap'
import {useAuth} from '../../security/AuthContextProvider'

function LoginPage(props) {
  const {login} = useAuth()
  const [cardClasses, setCardClasses] = React.useState('card-hidden')
  const [username, setUsername] = useState()
  const [password, setPassword] = useState()
  const [wrongCredential, setWrongCredential] = useState(false)

  React.useEffect(() => {
    setTimeout(function () {
      setCardClasses('')
    }, 1000)
  })

  const setAuth = value => {
    setAuthenticated(true)
    localStorage.setItem('sarrafeh-token', JSON.stringify(value.token))
    localStorage.setItem('authenticated', JSON.stringify(true))
    setAuthHeader(value.token)
  }

  const doLogin = () => {
    const loginDto = {
      username: username,
      password: password,
    }
    login(loginDto)
      .then(response => {
        if (response.status === 200) {
          props.history.push('/admin/dashboard')
          setAuth(response.data)
        }
      })
      .catch(() => setWrongCredential(true))
  }
  return (
    <>
      <div
        className="full-page section-image"
        data-color="black"
        data-image={require('assets/img/full-screen-image-3.jpg').default}
      >
        <div className="content d-flex align-items-center p-0">
          <Container>
            <Col className="mx-auto" lg="4" md="8">
              <Form action="" className="form" method="">
                <Card className={'card-login ' + cardClasses}>
                  <Card.Header>
                    <h3 className="header text-center">ورود</h3>
                  </Card.Header>
                  <Card.Body>
                    <Card.Body>
                      <Form.Group>
                        <label>نام کاربری</label>
                        <Form.Control
                          placeholder="نام کاربری"
                          type="text"
                          onChange={e => setUsername(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <label>رمز عبور</label>
                        <Form.Control
                          placeholder="رمز"
                          type="password"
                          onChange={e => setPassword(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                      <Form.Check className="pl-0">
                        <Form.Check.Label>
                          <Form.Check.Input
                            defaultChecked
                            type="checkbox"
                          ></Form.Check.Input>
                          <span className="form-check-sign"></span>
                          مرا بخاطر بسپار
                        </Form.Check.Label>
                      </Form.Check>
                      <Form.Group>
                        {wrongCredential && (
                          <label className="has-label has-error error">
                            نام کاربری و یا رمز عبور اشتباه است
                          </label>
                        )}
                      </Form.Group>
                    </Card.Body>
                  </Card.Body>
                  <Card.Footer className="ml-auto mr-auto">
                    <Button
                      className="btn-wd"
                      variant="warning"
                      onClick={doLogin}
                    >
                      ورود
                    </Button>
                  </Card.Footer>
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
        <div
          className="full-page-background"
          style={{
            backgroundImage:
              'url(' +
              require('assets/img/full-screen-image-3.jpg').default +
              ')',
          }}
        ></div>
      </div>
    </>
  )
}

export default LoginPage
