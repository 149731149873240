const saleStatuses = Object.freeze({
    QUEUE_CUSTOMER: {id: "C", title: "نیاز به TXID"},
    QUEUE_ADMIN: {id: "A", title: "در صف"},
    INVALID_TXID: {id: "I", title: "TXID نامعتبر"},
    DONE: {id: "D", title: "انجام شده"}
});

class SaleStatus
{
    getById(id){
        let selectedStatus =Object.entries(saleStatuses).find(value => value[1].id === id);
        return saleStatuses[selectedStatus[0]];
    }

    getValues(){
        return Object.keys(saleStatuses);
    }

}

export default new SaleStatus();
