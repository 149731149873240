import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom'

import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'assets/css/bootstrap-rtl.min.css'
// it was light-bootstrap-dashboard-pro-react-rtl
import 'assets/scss/global-style.scss'
import 'assets/scss/rtl-bugs-fixer.scss'

import AuthLayout from 'layouts/Auth.js'
import AdminLayout from 'layouts/Admin.js'
import './i18n'
import {AuthContextProvider} from './security/AuthContextProvider'
import PrivateRoute from './security/PrivateRoute'

ReactDOM.render(
  <AuthContextProvider>
    <BrowserRouter>
      <Switch>
        <Route path="/auth" render={props => <AuthLayout {...props} />} />
        <PrivateRoute
          path="/admin"
          render={props => <AdminLayout {...props} />}
        />
        <Redirect from="/" to="/admin/dashboard" />
      </Switch>
    </BrowserRouter>
  </AuthContextProvider>,
  document.getElementById('root'),
)
