const currencies = Object.freeze({
    BTC: {value: "BTC", label: "Bitcoin - بیت کوین",net:["BM"]},
    XRP: {value: "XRP", label: "Ripple - ریپل",net:["RN"]},
    TRX: {value: "TRX", label: "Tron - ترون",net:["TRC20"]},
    ETH: {value: "ETH", label: "Ether - اتر",net:["ERC20"]},
    USDT: {value: "USDT", label: "Tether - تتر",net:["ERC20","TRC20"]}
});

class Currency
{
    getById(value){
        let selectedStatus =Object.entries(currencies).find(cur => cur[1].value === value);
        return currencies[selectedStatus[0]];
    }

    getKeys(){
        return Object.keys(currencies);
    }
    getValues(){
        return Object.values(currencies);
    }

    getNetworks(value){
        return this.getById().net;
    }

}

export default new Currency();
