import React from "react";
import Button from "react-bootstrap/Button";
import { Container, Form } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import YesNo from "../../enums/YesNo";
import DateUtil from "../../utils/DateUtil";

export default function SaleDetail(props) {
  const { t } = useTranslation();
  const { sale, onHide } = props;

  return (
    <Modal
      size="lg"
      centered
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("sale.detail")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Card>
          <Card.Body>
            <Card.Text>
              <Container>
                <Row>
                  <Col xs={4} md={4}>
                    <div>
                      <h6>{t("common.id")}</h6>
                      <p className="category">{sale.id}</p>
                    </div>
                  </Col>
                  <Col xs={4} md={4}>
                    <div>
                      <h6>{t("common.closed")}</h6>
                      <p className="category">
                        {YesNo.getById(sale.closed).title}
                      </p>
                    </div>
                  </Col>

                  <Col xs={4} md={4}>
                    <div>
                      <h6>{t("common.create_datetime")}</h6>
                      <p className="category">
                        {DateUtil.convertToPersian(sale.create_datetime)}
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4} md={4}>
                    <div>
                      <h6>{t("sale.create_value")}</h6>
                      <p className="category">
                        {sale.create_value
                          .toString()
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                      </p>
                    </div>
                  </Col>
                  <Col xs={4} md={4}>
                    <div>
                      <h6>{t("common.dist_tag")}</h6>
                      <p className="category">{sale.dist_tag}</p>
                    </div>
                  </Col>
                  <Col xs={4} md={4}>
                    <div>
                      <h6>{t("common.done_datetime")}</h6>
                      <p className="category">
                        {DateUtil.convertToPersian(sale.done_datetime)}
                      </p>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xs={4} md={4}>
                    <div>
                      <h6>{t("common.invalid_txid")}</h6>
                      <p className="category">
                        {YesNo.getById(sale.invalid_txid).title}
                      </p>
                    </div>
                  </Col>
                  <Col xs={4} md={4}>
                    <div>
                      <h6>{t("common.to_admin")}</h6>
                      <p className="category">
                        {YesNo.getById(sale.to_admin).title}
                      </p>
                    </div>
                  </Col>
                  <Col xs={4} md={4}>
                    <div>
                      <h6>{t("sale.user_canceled")}</h6>
                      <p className="category">
                        {YesNo.getById(sale.user_canceled).title}
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4} md={4}>
                    <div>
                      <h6>{t("common.user")}</h6>
                      <p className="category">{sale.user}</p>
                    </div>
                  </Col>

                  <Col xs={4} md={4}>
                    <div>
                      <h6>txid</h6>
                      <p className="category">{sale.txid}</p>
                    </div>
                  </Col>

                  <Col xs={4} md={4}>
                    <div>
                      <h6>{t("common.curr_amount")}</h6>
                      <p className="category">{sale.curr_amount}</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4} md={4}>
                    <div>
                      <h6>{t("common.dist_tag")}</h6>
                      <p className="category">
                        {sale.assigned_wallet.dist_tag}
                      </p>
                    </div>
                  </Col>

                  <Col xs={4} md={4}>
                    <div>
                      <h6>{t("common.network")}</h6>
                      <p className="category">{sale.currency.network}</p>
                    </div>
                  </Col>
                  <Col xs={4} md={4}>
                    <div>
                      <h6>{t("common.currency")}</h6>
                      <p className="category">{sale.currency.name.fa}</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} md={6}>
                    <div>
                      <h6>{t("common.address")}</h6>
                      <p className="category">{sale.assigned_wallet.address}</p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Card.Text>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>{t("common.close")}</Button>
      </Modal.Footer>
    </Modal>
  );
}
