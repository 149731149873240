const affected = Object.freeze({
    ACTIVE: {id: true, title: "اعمال شده"},
    NOT_ACTIVE: {id: false, title: "اعمال نشده"}
});

class Affected
{
    getById(id){
        let selectedStatus =Object.entries(affected).find(value => value[1].id === id);
        return affected[selectedStatus[0]];
    }

    getValues(){
        return Object.keys(affected);
    }

}

export default new Affected();
