import React from 'react'
import Button from 'react-bootstrap/Button'
import {Container, Form} from 'react-bootstrap'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
import {useTranslation} from 'react-i18next'
import Verify from '../../enums/Verify'
import DateUtil from '../../utils/DateUtil'

export default function CustomerProfileDetail(props) {
  const {t} = useTranslation()
  const {profile, onHide} = props

  return (
    <Modal
      size="lg"
      centered
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('customerProfiles.detail')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Card>
          <Card.Body>
            <Card.Text>
              <Container>
                <Row>
                  <Col xs={4} md={4}>
                    <div>
                      <h6>{t('common.id')}</h6>
                      <p className="category">{profile.id}</p>
                    </div>
                  </Col>
                  <Col xs={4} md={4}>
                    <div>
                      <h6>{t('customerProfiles.table.fullName')}</h6>
                      <p className="category">{profile.name}</p>
                    </div>
                  </Col>
                  <Col xs={4} md={4}>
                    <div>
                      <h6>{t('customerProfiles.table.username')}</h6>
                      <p className="category">{profile.username}</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4} md={4}>
                    <div>
                      <h6>{t('customerProfiles.table.melliCode')}</h6>
                      <p className="category">{profile.melli_code}</p>
                    </div>
                  </Col>

                  <Col xs={4} md={4}>
                    <div>
                      <h6>{t('customerProfiles.table.city')}</h6>
                      <p className="category">{profile.city}</p>
                    </div>
                  </Col>

                  <Col xs={4} md={4}>
                    <div>
                      <h6>{t('customerProfiles.table.email')}</h6>
                      <p className="category">{profile.email}</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4} md={4}>
                    <div>
                      <h6>{t('customerProfiles.table.mobile')}</h6>
                      <p className="category">{profile.mobile}</p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Card.Text>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>{t('common.close')}</Button>
      </Modal.Footer>
    </Modal>
  )
}
