const verifies = Object.freeze({
    VERIFIED: {id: true, title: "تایید"},
    NOT_VERIFIED: {id: false, title: "عدم تایید"}
});

class Verify
{
    getById(id){
        let selectedStatus =Object.entries(verifies).find(value => value[1].id === id);
        return verifies[selectedStatus[0]];
    }

    getValues(){
        return Object.keys(verifies);
    }

}

export default new Verify();
