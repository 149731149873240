import React, {useState, useEffect, useMemo} from 'react'
import {Button, Card, Col, Container, Row} from 'react-bootstrap'
import {loadListNetwork, deleteNetwork} from '../../services/api'
import {useTranslation} from 'react-i18next'
import ReactTable from '../../components/ReactTable/ReactTable'
import AddNetwork from './AddNetwork'
import EditNetwork from './EditNetwork'

export default function ListNetwork(props) {
  const {t} = useTranslation()
  const [listNetwork, setListNetwork] = useState([])
  const [showAddNetwork, setShowAddNetwork] = useState(false)
  const [showEditNetwork, setShowEditNetwork] = useState(false)
  const [selectedNetwork, setSelectedNetwork] = useState(null)
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState(0)
  const [next, setNext] = useState('')

  const basketColumns = [
    {
      Header: t('common.id'),
      accessor: 'id',
      Footer: () => {
        return (
          <>
            {t('table.countRows')} {count}
          </>
        )
      },
    },
    {
      Header: t('network.name'),
      accessor: 'name',
    },
    {
      Header: t('network.symbol'),
      accessor: 'symbol',
    },
    {
      Header: t('common.actions'),
      accessor: 'actions',
    },
  ]

  const setSelectedvalue = value => {
    setSelectedNetwork(value)
  }

  const deletevalue = value => {
    deleteNetwork(value.id)
      .then(response => {
        alert('عملیات با موفقیت انجام شد')
        loadNewListNetwork({
          page: 1,
          size: 10,
        })
      })
      .catch(err => {
        alert(JSON.stringify(err))
      })
  }

  const loadNewListNetwork = data => {
    setLoading(true)
    loadListNetwork(data)
      .then(response => {
        setListNetwork(
          response.data.results.map(prop => {
            return {
              ...prop,
              name: prop.name.fa,
              actions: (
                <div className="actions-right">
                  <Button
                    onClick={() => {
                      setSelectedvalue(prop)
                      setShowEditNetwork(true)
                    }}
                    variant="warning"
                    size="sm"
                    className="text-warning btn-link edit"
                  >
                    <i className="fa fa-edit" />
                  </Button>

                  <Button
                    onClick={() => {
                      if (confirm('آیا از انجام این عملیات اطمینان دارید؟')) {
                        setSelectedvalue(prop)
                        deletevalue(prop)
                      } else {
                        // Do nothing!
                      }
                    }}
                    variant="danger"
                    size="sm"
                    className="text-danger btn-link delete"
                  >
                    <i className="fa fa-trash" />
                  </Button>
                </div>
              ),
            }
          }),
        )
        setCount(response.data.count)
        setNext(response.data.next)
        setLoading(false)
      })
      .catch(err => {
        alert('عملیات با خطا مواجه شد')
        alert(JSON.stringify(err))
        setLoading(false)
      })
  }

  const fetchData = (page, size) => {
    if (page > 0 && size > 0) {
      const data = {
        page: page,
        size: size,
      }
      loadNewListNetwork(data)
    }
  }

  useEffect(() => {
    fetchData(1, 10)
  }, [])
  return (
    <>
      <Container fluid>
        {!showAddNetwork && (
          <Button
            className="btn-fill"
            variant="info"
            onClick={e => setShowAddNetwork(true)}
          >
            + {t('network.add')}
          </Button>
        )}
        {showAddNetwork && (
          <AddNetwork
            addNewNetwok={setListNetwork}
            onClose={e => setShowAddNetwork(false)}
          />
        )}
        {showEditNetwork && (
          <EditNetwork
            editStateNetwork={setListNetwork}
            onClose={e => {
              setShowEditNetwork(false)
              loadNewListNetwork({
                page: 1,
                size: 10,
              })
            }}
            selectedNetwork={selectedNetwork}
          />
        )}

        <Row>
          <Col md="12">
            <Card>
              <Card.Body>
                <ReactTable
                  data={listNetwork}
                  count={count}
                  next={next}
                  columns={basketColumns}
                  className="-striped -highlight primary-pagination"
                  fetchingData={(pageIndex, pageSize) =>
                    fetchData(pageIndex, pageSize)
                  }
                  loading={loading}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}
