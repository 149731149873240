import React, {useEffect, useMemo, useState} from 'react'
import {Container, Button, Row, Col, Card} from 'react-bootstrap'
import ReactTable from '../../components/ReactTable/ReactTable'
import {useTranslation} from 'react-i18next'
import Network from '../../enums/Network'
import {FormGroup, Input} from 'reactstrap'
import Select from 'react-select'
import DateUtil from '../../utils/DateUtil'
import BestBuyActions from '../../enums/BestBuyAction'
import {getIncomePerUser, getIncomeSum} from '../../services/api'

export default function ReportPerUser(props) {
  const {t} = useTranslation()

  const initialStates = useMemo(
    () => ({
      Reports: [],
      loading: false,
      action: null,
      sum: '',
    }),
    [],
  )

  const [state, setState] = useState(initialStates)

  const {reports, loading, action, sum} = state

  const Columns = [
    {
      Header: t('common.customer_name'),
      size: 4,
      accessor: d => `${d.user_name} `,
    },
    ,
    {
      size: 4,
      Header: t('common.income_tether_sum'),
      accessor: d => d.income_tether_sum,
    },
  ]

  const actionOption = [
    {label: 'همه موارد', value: "['S','R','A']"},
    {label: 'بهینه سازی', value: 'R'},
    {label: 'افزایش سرمایه', value: 'A'},
    {label: 'فروش', value: 'S'},
  ]

  const fetchData = (page, size) => {
    if (page >= 0 && size >= 0) {
      const data = {
        action: action,
      }
      setState(prev => ({
        ...prev,
        loading: true,
      }))
      getIncomePerUser(data)
        .then(response => {
          setState(prev => ({
            ...prev,
            reports: response.data.data,
            loading: false,
          }))
        })
        .catch(err => {
          alert('عملیات با خطا مواجه شد')
          alert(JSON.stringify(err))
          setState(prev => ({
            ...prev,
            loading: false,
          }))
        })
    }
  }
  const handleSum = () => {
    const data = {
      action: action,
    }
    setState(prev => ({
      ...prev,
      loading: true,
    }))
    getIncomeSum(data)
      .then(response => {
        setState(prev => ({
          ...prev,
          sum: response.data.data.total_income,
          loading: false,
        }))
      })
      .catch(err => {
        alert('عملیات با خطا مواجه شد')
        alert(JSON.stringify(err))
        setState(prev => ({
          ...prev,
          loading: false,
        }))
      })
  }

  useEffect(() => {
    let timeout = null
    if (action) {
      if (!timeout) {
        timeout = setTimeout(() => {
          fetchData(1, 10)
        }, 800)
      }
    }

    return () => clearTimeout(timeout)
  }, [action])

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <div className="filter-area">
                <Col md="6">
                  <FormGroup>
                    <label>{t('common.action')}</label>
                    <Select
                      name="action"
                      options={actionOption}
                      palceholder="نوع تراکنش"
                      onChange={e => {
                        setState(prev => ({
                          ...prev,
                          action: e.value,
                        }))
                      }}
                    />
                  </FormGroup>
                </Col>
              </div>

              <Card.Body>
                <Card.Header>گزارش مجموع درامد از هر کاربر </Card.Header>
                <br />
                {reports && reports.length > 0 && (
                  <ReactTable
                    showPagination={false}
                    data={reports}
                    columns={Columns}
                    className="-striped -highlight primary-pagination"
                    loading={loading}
                  />
                )}
              </Card.Body>
              <Card.Body>
                <Col md="6" style={{marginTop: '1.7rem'}}>
                  <Button
                    variant="primary"
                    style={{marginLeft: '1rem'}}
                    onClick={handleSum}
                  >
                    نمایش مجموع کل درآمد
                  </Button>
                </Col>
                {sum && <Card.Header>مجموع درآمد ها : {sum}</Card.Header>}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}
