import React, {useState} from 'react'
import {Button, Card, Col, Container, Form, Row, Modal} from 'react-bootstrap'
import {Formik} from 'formik'
import {saveBasket, saveTestBasket} from '../../services/api'
import {useTranslation} from 'react-i18next'
import Select from 'react-select'

export default function BestBuyAnalysis(props) {
  const {t} = useTranslation()
  const {onClose, setBaskets, listCurrencies} = props
  let basket = {
    name: '',
  }
  const [selectCurrencies, setSelectCurrencies] = useState([])
  const [testMood, setTestMood] = useState(false)

  return (
    <>
      <Row sm={1} md={2}>
        <Formik
          initialValues={basket}
          onSubmit={values => {
            const valuesArray = Object.values(values)
            const numericValues = valuesArray.filter(
              value => typeof value === 'number',
            )

            const valuesSum = numericValues.reduce((curr, prev) => {
              if (typeof curr === 'number') return curr + prev
            }, 0)

            const valuesSumConverted = parseFloat(valuesSum.toFixed(1))

            if (valuesSumConverted !== 1) {
              alert(t('errors.bestBuy.basket.create'))
            } else {
              let flagValues = {
                name: '',
                analysis: {},
              }
              let testValue = {
                basket_to_test: {},
              }
              for (let [key, value] of Object.entries(values)) {
                if (key !== 'name') {
                  flagValues.analysis[key] = value
                  testValue.basket_to_test[key] = value
                } else {
                  flagValues.name = value
                }
              }

              if (!testMood) {
                saveBasket(flagValues)
                  .then(response => {
                    alert('عملیات با موفقیت انجام شد')
                    setBaskets(prev => [...prev, response.data])
                    onClose()
                  })
                  .catch(err => alert(JSON.stringify(err.response.data)))
              } else {
                console.log(testMood)
                saveTestBasket(testValue)
                  .then(response => {
                    alert(JSON.stringify(response.data))

                    // onClose()
                  })
                  .catch(err => alert(JSON.stringify(err.response.data)))
              }
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Card className="stacked-form">
                <Card.Header>
                  <Card.Title as="h4">{t('bestBuy.basket.form')}</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Form.Group>
                    <label>{t('bestBuy.basket.name')}</label>
                    <Form.Control
                      placeholder="name"
                      type="text"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    ></Form.Control>
                  </Form.Group>
                  <label>انتخاب ارز</label>
                  <Select
                    name="currencies"
                    isMulti
                    options={
                      selectCurrencies.length > 9 ? [] : listCurrencies.data
                    }
                    placeholder="ارز"
                    onChange={item =>
                      item?.length > 0
                        ? setSelectCurrencies(item)
                        : setSelectCurrencies([])
                    }
                    onBlur={handleBlur}
                  />
                  <Row xs={1} sm={4} className="mt-3">
                    {selectCurrencies.length > 0 &&
                      selectCurrencies.map((item, index) => (
                        <Col key={index}>
                          <Form.Group>
                            <label>{item.value}</label>
                            <Form.Control
                              placeholder={item.value}
                              type="number"
                              name={item.value}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values[item.value]}
                              step="0.1"
                              min="0"
                              max="1"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      ))}
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <Row>
                    <Col md={2} className="ml-3">
                      <Button
                        className="btn-fill"
                        type="submit"
                        variant="info"
                        onClick={() => {
                          setTestMood(false)
                        }}
                      >
                        {t('common.save')}
                      </Button>
                    </Col>
                    <Col md={2} className="ml-3">
                      <Button
                        type="submit"
                        className="btn-fill"
                        onClick={() => {
                          setTestMood(true)
                        }}
                        variant="info"
                      >
                        test
                      </Button>
                    </Col>
                    <Col md={2}>
                      <Button
                        className="btn-fill"
                        onClick={onClose}
                        variant="info"
                      >
                        {t('common.close')}
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Form>
          )}
        </Formik>
      </Row>
    </>
  )
}
