import React from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Pagination,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function AdminFooter() {
  const [t] = useTranslation();
  return (
    <>
      <footer className="footer">
        <Container fluid className="pl-4 ml-2">
          <nav>
            <ul className="footer-menu">
              <li>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  {t('layout.footer.home')}
                </a>
              </li>
              <li>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  {t('layout.footer.company')}
                </a>
              </li>
              <li>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  {t('layout.footer.portfolio')}
                </a>
              </li>
              <li>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  {t('layout.footer.blog')}
                </a>
              </li>
            </ul>
            <p className="copyright text-center">
              © <script>document.write(new Date().getFullYear())</script>
              <a href="http://www.coin-plus.org">{t('layout.appName')}</a>,{t('layout.footer.copyRight')}
            </p>
          </nav>
        </Container>
      </footer>
    </>
  );
}

export default AdminFooter;
