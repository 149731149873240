const withdrawStatuses = Object.freeze({
    DONE: {id: true, title: "انجام شده"},
    IN_PROGRESS: {id: false, title: "در صف"}
});

class WithdrawStatus
{
    getById(id){
        let selectedStatus =Object.entries(withdrawStatuses).find(value => value[1].id === id);
        return withdrawStatuses[selectedStatus[0]];
    }

    getValues(){
        return Object.keys(withdrawStatuses);
    }

}

export default new WithdrawStatus();
