import {Container, Form, Button, Row, Col, Card, Modal} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import DateUtil from '../../utils/DateUtil'

export default function WithdrawDetail(props) {
  const {t} = useTranslation()
  const {purchase, onHide} = props

  return (
    <Modal
      size="lg"
      centered
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('withdrawReq.detail')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Card>
          <Card.Body>
            <Card.Text>
              <Container>
                <Row>
                  <Col xs={4} md={4}>
                    <div>
                      <h6>{t('common.amount')}</h6>
                      <p className="category">{purchase.amount}</p>
                    </div>
                  </Col>
                  <Col xs={4} md={4}>
                    <div>
                      <h6>{t('common.currency')}</h6>
                      <p className="category">{purchase.currency}</p>
                    </div>
                  </Col>
                  <Col xs={4} md={4}>
                    <div>
                      <h6>txid</h6>
                      <p className="category">{purchase.txid}</p>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xs={4} md={4}>
                    <div>
                      <h6>{t('common.last_update')}</h6>
                      <p className="category">
                        {DateUtil.convertToPersian(purchase.last_update)}
                      </p>
                    </div>
                  </Col>
                  <Col xs={4} md={4}>
                    <div>
                      <h6>{t('common.tracker_code')}</h6>
                      <p className="category">{purchase.tracker_code}</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4} md={4}>
                    <div>
                      <h6>{t('common.user')}</h6>
                      <p className="category">{purchase.user}</p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Card.Text>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>{t('common.close')}</Button>
      </Modal.Footer>
    </Modal>
  )
}
