import React, {useEffect, useMemo, useState} from 'react'
import {Container, Button, Row, Col, Card} from 'react-bootstrap'
import ReactTable from '../../components/ReactTable/ReactTable'
import {useTranslation} from 'react-i18next'
import Network from '../../enums/Network'
import BasketDetail from './BasketDetail'
import BasketHistoryDetail from './BasketHistoryDetail'
import DateUtil from '../../utils/DateUtil'
import BestBuyActions from '../../enums/BestBuyAction'
import {
  loadBestBuyBasketList,
  loadBestBuyBasketHistory,
} from '../../services/api'

import './BestBuyBasketList.css'

export default function BestBuyBasketsList(props) {
  const {t} = useTranslation()

  const initialStates = useMemo(
    () => ({
      bestBuyBasketsList: [],
      selectedBestBuyBaskets: false,
      showDetailDialog: false,
      showDetail: false,
      loading: false,
      page: 0,
      size: 0,
      count: 0,
      next: '',
      previous: '',
      sort_field: '-id',
      basketsHistoryList: [],
      historyLoading: false,
      historyPage: 0,
      historySize: 0,
      historyCount: 0,
      historyNext: '',
      historyPrevious: '',
      selectedHistory: false,
      showDetailHistory: false,
      allBasketPrice: 0,
    }),
    [],
  )

  const [state, setState] = useState(initialStates)

  const {
    bestBuyBasketsList,
    selectedBestBuyBaskets,
    showDetailDialog,
    showDetail,
    loading,
    page,
    size,
    count,
    next,
    previous,
    sort_field,
    basketsHistoryList,
    historyLoading,
    historyPage,
    historySize,
    historyCount,
    historyNext,
    historyPrevious,
    selectedHistory,
    showDetailHistory,
    allBasketPrice,
  } = state

  const bestBuyBasketColumns = [
    {
      Header: t('common.id'),
      accessor: 'id',
      width: '2%',
      Footer: info => {
        const total = useMemo(() => info.rows.length)
        return (
          <>
            {t('table.countRows')} {count}
          </>
        )
      },
    },

    {
      Header: t('bestBuy.basket-price-usdt'),
      accessor: `basket_price.usdt`,
      Footer: info => {
        return (
          <div className="table-footer-cell">
            {t('table.allBasketPrice')} :{' '}
            {parseFloat(allBasketPrice).toFixed(2)}
          </div>
        )
      },
    },
    {
      Header: t('common.customer_name'),
      size: 4,
      accessor: d => `${d.customer_data.name} `,
    },
    {
      Header: t('common.create_datetime'),
      accessor: d => `${DateUtil.convertToJalaliBirthDate(d.created_on)}`,
    },
    {
      Header: t('bestBuy.last_update'),
      accessor: d => `${DateUtil.convertToJalaliBirthDate(d.last_update)}`,
    },
    {
      Header: t('common.actions'),
      accessor: 'actions',
      sortable: false,
      filterable: false,
    },
  ]

  const dataBestBuyBasketHandler = data => {
    const bestBuyBasket = data.map((prop, key) => {
      return {
        ...prop,
        actions: (
          <div className="actions-right">
            <Button
              onClick={() => {
                setState(prev => ({
                  ...prev,
                  selectedBestBuyBaskets: prop,
                  showDetail: true,
                }))
              }}
              variant="warning"
              size="sm"
              className="text-warning btn-link edit"
            >
              <i class="fa fa-list"></i>
            </Button>
            <Button
              onClick={() => {
                setState(prev => ({
                  ...prev,
                  selectedBestBuyBaskets: prop,
                  showDetailDialog: true,
                }))
              }}
              variant="info"
              size="sm"
              className="text-info btn-link like"
            >
              <i className="fa fa-eye" />
            </Button>
          </div>
        ),
      }
    })
    setState(prev => ({
      ...prev,
      bestBuyBasketsList: bestBuyBasket,
    }))
  }

  const fetchData = (page, size) => {
    if (page > 0 && size > 0) {
      const data = {
        page: page,
        size: size,
        sort_field: sort_field,
      }
      setState(prev => ({
        ...prev,
        loading: true,
      }))
      loadBestBuyBasketList(data)
        .then(response => {
          dataBestBuyBasketHandler(response.data.results)

          let totalPrice = 0
          response.data.results.forEach(obj => {
            totalPrice += obj.basket_price.usdt
          })
          console.log(totalPrice)
          setState(prev => ({
            ...prev,
            count: response.data.count,
            next: response.data.next,
            previous: response.data.previous,
            allBasketPrice: totalPrice,
            loading: false,
          }))
        })
        .catch(err => {
          alert('عملیات با خطا مواجه شد')
          alert(JSON.stringify(err))
          setState(prev => ({
            ...prev,
            loading: false,
          }))
        })
    }
  }

  useEffect(() => {
    let timeout = null
    if (!timeout) {
      timeout = setTimeout(() => {
        fetchData(1, 10)
      }, 800)
    }

    return () => clearTimeout(timeout)
  }, [])

  const historyColumns = [
    {
      Header: t('common.id'),
      accessor: 'id',
      Footer: info => {
        const total = useMemo(() => info.rows.length)
        return (
          <>
            {t('table.countRows')} {total}
          </>
        )
      },
    },
    {
      Header: t('customerProfiles.table.date'),
      accessor: d => `${DateUtil.convertToJalaliBirthDate(d.datetime)}`,
    },
    {
      Header: t('bestBuy.history.amount_added_tether'),
      accessor: d =>
        d.amount_added_tether &&
        d.amount_added_tether
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
    },
    {
      Header: t('bestBuy.history.income'),
      accessor: d =>
        d.income_tether &&
        d.income_tether.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
    },
    {
      Header: t('customerProfiles.table.action'),
      accessor: d => BestBuyActions.getById(d.action).title,
    },
    {
      Header: t('common.actions'),
      accessor: 'actions',
      sortable: false,
      filterable: false,
    },
  ]

  const dataBasketHistoryHandler = data => {
    const basketHistory = data.map((prop, key) => {
      return {
        ...prop,
        actions: (
          <div className="actions-right">
            <Button
              onClick={() => {
                setState(prev => ({
                  ...prev,
                  selectedHistory: prop,
                  showDetailHistory: true,
                }))
              }}
              variant="info"
              size="sm"
              className="text-info btn-link like"
            >
              <i className="fa fa-eye" />
            </Button>
          </div>
        ),
      }
    })
    setState(prev => ({
      ...prev,
      basketsHistoryList: basketHistory,
    }))
  }

  const fetchDataHistory = (page, size) => {
    if (page > 0 && size > 0) {
      const data = {
        id: selectedBestBuyBaskets.user,
        page: page,
        size: size,
      }
      setState(prev => ({
        ...prev,
        historyLoading: true,
      }))
      loadBestBuyBasketHistory(data)
        .then(response => {
          dataBasketHistoryHandler(response.data.results)
          setState(prev => ({
            ...prev,
            historyCount: response.data.count,
            historyNext: response.data.next,
            historyPrevious: response.data.previous,
            historyLoading: false,
          }))
        })
        .catch(err => {
          alert('عملیات با خطا مواجه شد')
          alert(JSON.stringify(err))
          setState(prev => ({
            ...prev,
            loading: false,
          }))
        })
    }
  }

  useEffect(() => {
    if (showDetail && selectedBestBuyBaskets.user) {
      fetchDataHistory(1, 10)
    }
  }, [showDetail, selectedBestBuyBaskets])

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            {!showDetail && (
              <>
                {selectedBestBuyBaskets && (
                  <BasketDetail
                    basket={selectedBestBuyBaskets}
                    show={showDetailDialog}
                    onHide={() =>
                      setState(prev => ({
                        ...prev,
                        showDetailDialog: false,
                      }))
                    }
                  />
                )}

                <Card>
                  <Card.Body>
                    <Card.Header>سبد های خرید بهینه</Card.Header>
                    <br />
                    <ReactTable
                      data={bestBuyBasketsList}
                      count={count}
                      columns={bestBuyBasketColumns}
                      next={next}
                      prev={previous}
                      className="-striped -highlight primary-pagination"
                      fetchingData={(pageIndex, pageSize) =>
                        fetchData(pageIndex, pageSize)
                      }
                      loading={loading}
                    />
                  </Card.Body>
                </Card>
              </>
            )}

            {showDetail && (
              <>
                {selectedHistory && (
                  <BasketHistoryDetail
                    history={selectedHistory}
                    show={showDetailHistory}
                    onHide={() =>
                      setState(prev => ({
                        ...prev,
                        showDetailHistory: false,
                      }))
                    }
                  />
                )}

                <Card>
                  <Card.Body>
                    <Card.Header>
                      <h6>تاریخچه سبد بهینه</h6>

                      <Button
                        onClick={() => {
                          window.location.reload()
                          setState(prev => ({
                            ...prev,
                            showDetail: false,
                          }))
                        }}
                        variant="warning"
                        size="sm"
                        className="text-info btn-link like"
                      >
                        بازگشت
                        <i class="fa fa-arrow-left"></i>
                      </Button>
                    </Card.Header>
                    <br />

                    <ReactTable
                      className="-striped -highlight primary-pagination"
                      data={basketsHistoryList}
                      columns={historyColumns}
                      count={historyCount}
                      next={historyNext}
                      previous={historyPrevious}
                      fetchingData={(pageIndex, pageSize) =>
                        fetchDataHistory(pageIndex, pageSize)
                      }
                      loading={historyLoading}
                    />
                  </Card.Body>
                </Card>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  )
}
