import React, {useEffect, useMemo, useState} from 'react'
import {Container, Button, Row, Col, Card} from 'react-bootstrap'
import ReactTable from '../../components/ReactTable/ReactTable'
import {useTranslation} from 'react-i18next'
import Network from '../../enums/Network'
import {FormGroup, Input} from 'reactstrap'
import Select from 'react-select'
import DateUtil from '../../utils/DateUtil'
import BestBuyActions from '../../enums/BestBuyAction'
import {getIncomeReport, getIncomeReportCSV} from '../../services/api'

export default function Reports(props) {
  const {t} = useTranslation()

  const initialStates = useMemo(
    () => ({
      Reports: [],
      loading: false,
      page: 0,
      size: 0,
      count: 0,
      next: '',
      previous: '',
      sort_field: '-id',
      start_date: null,
      end_date: null,
      name: null,
      action: null,
    }),
    [],
  )

  const [state, setState] = useState(initialStates)

  const {
    reports,
    loading,
    page,
    size,
    count,
    next,
    previous,
    start_date,
    end_date,
    name,
    action,
    sort_field,
  } = state

  const Columns = [
    {
      Header: t('common.portfolio-id'),
      accessor: 'portfolio',
      width: '2%',
      Footer: info => {
        const total = useMemo(() => info.rows.length)
        return (
          <>
            {t('table.countRows')} {count}
          </>
        )
      },
    },
    {
      Header: t('customerProfiles.table.action'),
      accessor: d => BestBuyActions.getById(d.action).title,
    },
    {
      Header: t('common.customer_name'),
      size: 4,
      accessor: d => `${d.customer_name} `,
    },
    {
      Header: t('common.create_datetime'),
      accessor: d => `${DateUtil.convertToJalaliBirthDate(d.datetime)}`,
    },
    {
      Header: t('bestBuy.history.income'),
      accessor: d =>
        d.income_tether &&
        d.income_tether.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
    },
    {
      Header: t('bestBuy.history.amount_added_tether'),
      accessor: d =>
        d.amount_added_tether &&
        d.amount_added_tether
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
    },

    {
      Header: t('common.mobile'),
      accessor: 'customer_mobile',
    },
  ]

  const actionOption = [
    {label: 'همه موارد', value: "['S','R','A']"},
    {label: 'بهینه سازی', value: 'R'},
    {label: 'افزایش سرمایه', value: 'A'},
    {label: 'فروش', value: 'S'},
  ]

  const fetchData = (page, size) => {
    if (page >= 0 && size >= 0) {
      const data = {
        page: page,
        size: size,
        start_date: start_date,
        end_date: end_date,
        name: name?.replace(/\s/g, ''),
        action: action,
        ordering: sort_field,
      }
      setState(prev => ({
        ...prev,
        loading: true,
      }))
      getIncomeReport(data)
        ?.then(response => {
          setState(prev => ({
            ...prev,
            reports: response.data.data.results,
            count: response.data.data.count,
            next: response.data.data.next,
            previous: response.data.data.previous,
            loading: false,
          }))
        })
        .catch(err => {
          alert('عملیات با خطا مواجه شد')
          alert(JSON.stringify(err))
          setState(prev => ({
            ...prev,
            loading: false,
          }))
        })
    }
  }

  const handleData = () => {
    fetchData(1, 10)
  }
  const handleExport = () => {
    const data = {
      start_date: start_date,
      end_date: end_date,
      name: name?.replace(/\s/g, ''),
      action: action,
      ordering: sort_field,
    }
    getIncomeReportCSV(data).catch(err => {
      alert('عملیات با خطا مواجه شد')
      alert(JSON.stringify(err))
    })
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <div className="filter-area">
                <Col md="3">
                  <FormGroup>
                    <label>نام</label>
                    <Input
                      name="name"
                      placeholder="نام"
                      type="text"
                      onChange={e => {
                        setState(prev => ({
                          ...prev,
                          name: JSON.stringify(e.target.value) || '',
                        }))
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <label>از تاریخ</label>
                    <Input
                      name="start_date"
                      placeholder="2022-1-1"
                      type="text"
                      onChange={e => {
                        setState(prev => ({
                          ...prev,
                          start_date: e.target.value || '',
                        }))
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <label>تا تاریخ</label>
                    <Input
                      name="end_date"
                      placeholder="2022-10-10"
                      type="text"
                      onChange={e => {
                        setState(prev => ({
                          ...prev,
                          end_date: e.target.value || '',
                        }))
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <label>وضعیت</label>
                    <Select
                      name="action"
                      options={actionOption}
                      palceholder="نوع تراکنش"
                      onChange={e => {
                        setState(prev => ({
                          ...prev,
                          action: e.value,
                        }))
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" style={{marginTop: '1.7rem'}}>
                  <Button
                    variant="primary"
                    style={{marginLeft: '1rem'}}
                    onClick={handleData}
                  >
                    نمایش گزارش
                  </Button>

                  <Button variant="secondary" onClick={handleExport}>
                    خروجی اکسل
                  </Button>
                </Col>
              </div>

              <Card.Body>
                <Card.Header>گزارشات </Card.Header>
                <br />
                {reports && reports?.length > 0 && (
                  <ReactTable
                    data={reports}
                    count={count}
                    columns={Columns}
                    next={next}
                    prev={previous}
                    className="-striped -highlight primary-pagination"
                    fetchingData={(pageIndex, pageSize) =>
                      fetchData(pageIndex, pageSize)
                    }
                    loading={loading}
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}
