import React, {useEffect, useMemo, useState} from 'react'
import {loadBaskets, currencies} from '../../services/api'
import {Button, Container} from 'react-bootstrap'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import {Media, Table} from 'react-bootstrap'
import ReactTable from '../../components/ReactTable/ReactTable'
import {useTranslation} from 'react-i18next'
import BestBuyAnalysis from './BestBuyAnalysis'
import DateUtil from '../../utils/DateUtil'
import MyModal from '../../components/Modal/MyModal'

export default function BestBuyBaskets(props) {
  const {t} = useTranslation()
  const [showCreateBasket, setShowCreateBasket] = useState(false)
  const basketColumns = [
    {
      Header: t('common.id'),
      accessor: 'id',
      Footer: info => {
        const total = useMemo(() => info.rows.length)
        return (
          <>
            {t('table.countRows')} {total}
          </>
        )
      },
    },
    {
      id: 'datetime',
      Header: t('bestBuy.basket.table.dateTime'),
      accessor: d => DateUtil.convertToPersian(d.datetime),
    },
    {
      Header: t('bestBuy.basket.name'),
      accessor: 'name',
    },
    {
      Header: t('bestBuy.basket.table.user'),
      accessor: 'user',
    },
    {
      Header: t('common.actions'),
      accessor: 'actions',
      sortable: false,
      filterable: false,
    },
  ]
  const [baskets, setBaskets] = useState([])
  const [arrayCurrencies, setArrayCurrencies] = useState({data: []})

  const generateBodyModal = data => {
    return (
      <Table striped bordered hover>
        <tbody>
          {Object.entries(data).map((item, index) => (
            <tr key={index}>
              <td className="text-center">{item[0]}</td>
              <td className="text-center">{item[1]}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

  const fetchDataBestBuyBaskets = () => {
    loadBaskets()
      .then(response =>
        setBaskets(
          response.data
            .map((prop, key) => {
              return {
                ...prop,
                actions: (
                  <MyModal
                    title="مقادیر ارز"
                    body={generateBodyModal(prop.analysis)}
                    launch={<i className="fa fa-edit" />}
                    styles={{
                      variant: 'warning',
                      size: 'sm',
                      className: 'text-warning btn-link edit',
                    }}
                  />
                ),
              }
            })
            .sort((a, b) => (a.id > b.id ? -1 : 1)),
        ),
      )
      .catch(err => console.log(err))
  }

  const loadCurrenciesList = () => {
    currencies({
      page: 1,
      size: 500,
    })
      .then(response => {
        response.data.results.map(item => {
          let flagDta = {}
          flagDta.label = (
            <Media>
              <img
                width="30"
                height="30"
                className="ml-3 mx-1 my-auto"
                src={item.logo}
                alt={item.name.fa}
              />
              <Media.Body>
                <h5>{item.name.fa}</h5>
                <span className="text-muted">{item.symbol}</span>
              </Media.Body>
            </Media>
          )
          flagDta.value = item.symbol

          arrayCurrencies.data.push(flagDta)
          setArrayCurrencies({...arrayCurrencies})
        })
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    fetchDataBestBuyBaskets()
    loadCurrenciesList()
  }, [])

  return (
    <>
      <Container fluid>
        {!showCreateBasket && (
          <Button
            className="btn-fill"
            variant="info"
            onClick={e => setShowCreateBasket(true)}
          >
            + {t('bestBuy.basket.create')}
          </Button>
        )}
        {showCreateBasket && (
          <BestBuyAnalysis
            listCurrencies={arrayCurrencies}
            setBaskets={setBaskets}
            onClose={e => {
              setShowCreateBasket(false)
              fetchDataBestBuyBaskets()
            }}
          />
        )}
        {baskets.length > 0 && (
          <Row>
            <Col md="12">
              <Card>
                <Card.Body>
                  <ReactTable
                    showPagination={false}
                    data={baskets}
                    columns={basketColumns}
                    className="-striped -highlight primary-pagination"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </>
  )
}
