import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {Form} from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import {
  calculateChargeOrderPrice,
  finalizeChargeOrder,
} from '../../services/api'
import {useTranslation} from 'react-i18next'

export default function ChargeResult(props) {
  const {charge, onHide} = props
  const [totalPrice, setTotalPrice] = useState(0)
  const [unitPrice, setUnitPrice] = useState(0)
  const [currencyAmount, setCurrencyAmount] = useState(0)
  const [transactionDoneTime, setTransactionDoneTime] = useState()
  const [transactionDoneTimeState, setTransactionDoneTimeState] = useState(true)
  const [showSecondPart, setShowSecondPart] = useState(false)
  const {t} = useTranslation()

  const confirmation_TXID_Link =
    charge.currency === 'TRX'
      ? `https://tronscan.org/#/transaction/${charge.txid}`
      : `https://blockchair.com/search?q=${charge.txid}&_search=homepage`

  const invalidateOrder = () => {
    let chargeResultDto = {invalid_txid: true, to_admin: false}
    finalizeChargeOrder(charge.id, chargeResultDto)
      .then(response => {
        onHide()
      })
      .catch(err => alert(JSON.stringify(err)))
  }

  const calculateChargePrice = () => {
    let requestDto = {done_datetime: transactionDoneTime}
    calculateChargeOrderPrice(charge.id, requestDto)
      .then(response => {
        setTotalPrice(response.data.total)
        setUnitPrice(response.data.cudst_usdtrls)
        console.log(response)
        setShowSecondPart(true)
        setCurrencyAmount(charge.curr_amount)
      })
      .catch(err => alert(JSON.stringify(err)))
  }

  const finalizeOrder = () => {
    let chargeResultDto = {
      invalid_txid: false,
      to_admin: false,
      done_tether_per_curr: unitPrice,
      done_total: totalPrice,
      done_datetime: transactionDoneTime,
    }
    finalizeChargeOrder(charge.id, chargeResultDto)
      .then(response => {
        onHide()
      })
      .catch(err => alert(JSON.stringify(err)))
  }

  useEffect(() => {
    setShowSecondPart(false)
    setTotalPrice(0)
    setUnitPrice(0)
    setTransactionDoneTime(null)
  }, [onHide])

  return (
    <Modal
      size="lg"
      centered
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('charge.transaction.info')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Card>
          <Card.Body>
            <Card.Text>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <a target="_blank" href={confirmation_TXID_Link}>
                      TXID Confirmation Link
                    </a>
                  </Col>
                  <Col xs={12} md={12}>
                    <Form.Group
                      className={
                        'has-label ' +
                        (transactionDoneTimeState ? 'has-success' : 'has-error')
                      }
                    >
                      <label>
                        {t('charge.transaction.doneTime')}
                        <span className="star">*</span>
                      </label>
                      <p>sample: 2021-3-10T12:12:12</p>
                      <Form.Control
                        name="txTime"
                        type="text"
                        value={transactionDoneTime}
                        onChange={e => {
                          setTransactionDoneTime(e.target.value)
                          if (!e.target.value) {
                            setTransactionDoneTimeState(false)
                          } else setTransactionDoneTimeState(true)
                        }}
                      ></Form.Control>
                      {transactionDoneTimeState ? null : (
                        <label className="error">This field is required.</label>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                {showSecondPart && (
                  <Row>
                    <Col xs={12} md={12}>
                      <Form.Group
                        className={
                          'has-label ' +
                          (transactionDoneTimeState
                            ? 'has-success'
                            : 'has-error')
                        }
                      >
                        <label>
                          {t('charge.transaction.unitPrice')}{' '}
                          <span className="star">*</span>
                        </label>
                        <Form.Control
                          name="unitPrice"
                          type="text"
                          value={unitPrice}
                          onChange={e => {
                            setUnitPrice(e.target.value)
                            if (!e.target.value) {
                              setTransactionDoneTimeState(false)
                            } else setTransactionDoneTimeState(true)
                          }}
                          readOnly
                        ></Form.Control>
                        {/* {transactionDoneTimeState ? null : (
                          <label className="error">
                            This field is required.
                          </label>
                        )} */}
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                {showSecondPart && (
                  <Row>
                    <Col xs={12} md={12}>
                      <Form.Group
                        className={
                          'has-label ' +
                          (transactionDoneTimeState
                            ? 'has-success'
                            : 'has-error')
                        }
                      >
                        <label>
                          {t('charge.transaction.totalPrice')}{' '}
                          <span className="star">*</span>
                        </label>
                        <Form.Control
                          name="totalPrice"
                          type="text"
                          value={totalPrice}
                          onChange={e => {
                            setTotalPrice(e.target.value)
                            if (!e.target.value) {
                              setTransactionDoneTimeState(false)
                            } else setTransactionDoneTimeState(true)
                          }}
                          readOnly
                        ></Form.Control>
                        {/* //  {transactionDoneTimeState ? null : (
                         <label className="error">
                            This field is required.
                          </label>
                        )}*/}
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                {showSecondPart && (
                  <Row>
                    <Col xs={12} md={12}>
                      <Form.Group
                        className={
                          'has-label ' +
                          (transactionDoneTimeState
                            ? 'has-success'
                            : 'has-error')
                        }
                        // setTotalPrice
                      >
                        <label>
                          {t('charge.transaction.currencyAmount')}{' '}
                          <span className="star">*</span>
                        </label>
                        <Form.Control
                          name="currencyAmount"
                          type="text"
                          value={currencyAmount}
                          onChange={e => {
                            setCurrencyAmount(e.target.value)
                            if (unitPrice && e.target.value) {
                              let newTotal = e.target.value * unitPrice
                              setTotalPrice(parseFloat(newTotal))
                            }
                            if (!e.target.value) {
                              setTransactionDoneTimeState(false)
                            } else setTransactionDoneTimeState(true)
                          }}
                        ></Form.Control>
                        {transactionDoneTimeState ? null : (
                          <label className="error">
                            This field is required.
                          </label>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                )}
              </Container>
            </Card.Text>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>{t('common.close')}</Button>
        <Button onClick={invalidateOrder}>
          {t('charge.transaction.invalidate')}
        </Button>
        <Button onClick={calculateChargePrice}>
          {t('charge.transaction.calculatePrice')}
        </Button>
        <Button onClick={finalizeOrder}>
          {t('charge.transaction.finalize')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
