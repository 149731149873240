import React from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import CustomerProfileStatus from "../../enums/CustomerProfileStatus";

export default function VerifyPersonalPicture(props){

    const {personalPhoto,setVerification} = props;
    const {t} = useTranslation();
    const handleVerify =(event) =>{
        let verified = event.target.value;
        setVerification(prev => ({...prev,isPersonalPhotoVerified:verified}));
    }

    return (
        <Card >
            <Card.Body>
                <Card.Title>{t('customerProfiles.picture.personal')}</Card.Title>
                <Card.Text>
                    <Container>
                        <Row>
                            <Col xs={12} md={12}>
                                <p>{t('customerProfiles.picture.verificationStatus')}: {CustomerProfileStatus.getById(personalPhoto.personal_photo_cart_melli_verification).title}</p>
                            </Col>
                        </Row>
                    </Container>
                </Card.Text>
                {personalPhoto.personal_photo_cart_melli_type ?
                    <video style={{width: '100%'}} src={personalPhoto.personal_photo_cart_melli} controls/>
                :
                    <Card.Img variant="top" src={personalPhoto.personal_photo_cart_melli} />
                }
                
                <Row>
                    <Col xs={12} md={12}>
                        <fieldset>
                            <Form.Group>
                                <Row>
                                    <Col xs={12} md={10}>
                                        <Form.Check className="form-check-radio">
                                            <Form.Check.Label>
                                                <Form.Check.Input
                                                    defaultChecked={personalPhoto.personal_photo_cart_melli_verification === "V"}
                                                    defaultValue="V"
                                                    id="verifiedPic"
                                                    name="verifiedPic"
                                                    type="radio"
                                                    onChange={handleVerify}
                                                ></Form.Check.Input>
                                                <span className="form-check-sign"></span>
                                                {t('customerProfiles.picture.verified')}
                                            </Form.Check.Label>
                                        </Form.Check>
                                        <Form.Check className="form-check-radio">
                                            <Form.Check.Label>
                                                <Form.Check.Input
                                                    defaultChecked={personalPhoto.personal_photo_cart_melli_verification === "R"}
                                                    defaultValue="R"
                                                    id="notVerifiedPic"
                                                    name="verifiedPic"
                                                    type="radio"
                                                    onChange={handleVerify}
                                                ></Form.Check.Input>
                                                <span className="form-check-sign"></span>
                                                {t('customerProfiles.picture.notVerified')}
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </fieldset>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );

}
